/** @format */

import React, { useState, useEffect } from "react";
import {
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar,
	IonIcon,
	IonButtons,
	IonButton,
	IonPopover,
	IonListHeader,
	IonBackButton,
} from "@ionic/react";
import { useGoogleMaps } from "react-hook-google-maps";

import { options } from "ionicons/icons";
// Redux
import { useDispatch, useSelector } from "react-redux";
import { initMap, mapReducer, setMarkersStatus } from "../redux/mapSlice";
import { RouteComponentProps } from "react-router";
import { Marker } from "google-maps-react";
interface MapProps
	extends RouteComponentProps<{
		lat: string;
		lng: string;
		zoom: string;
	}> {}
const Map: React.FC<MapProps> = ({ match }) => {
	const dispatch = useDispatch();
	const mapSlice = useSelector(mapReducer);
	const { ref, map, google } = useGoogleMaps(
		"AIzaSyCtHqO9dHxLM8Mx5rdkD6Z7Hz0x8ag0TqE",
		{
			center: {
				lat: parseFloat(match.params.lat),
				lng: parseFloat(match.params.lng),
			},
			zoom: parseFloat(match.params.zoom),
			styles: mapSlice.map.styles.night,
		}
	);
	useEffect(() => {
		dispatch(initMap({ init: true }));
	}, [dispatch]);

	useEffect(() => {
		if (mapSlice.map.scans.length > 0 && google) {
			// eslint-disable-next-line array-callback-return
			mapSlice.map.scans.map((scan: any) => {
				var marker = new google.maps.Marker({
					position: {
						lat: parseFloat(scan.lat),
						lng: parseFloat(scan.lon),
					},
					title: scan.id,
					icon: {
						url:
							scan.testresult === "negative"
								? "http://maps.google.com/mapfiles/ms/icons/green-dot.png"
								: "http://maps.google.com/mapfiles/ms/icons/red-dot.png",
					},
				});
				marker.setMap(map);
				dispatch(setMarkersStatus(true));
			});
		}
	}, [dispatch, google, map, mapSlice.map.markersStatus, mapSlice.map.scans]);
	const [showPopover, setShowPopover] = useState<{
		open: boolean;
		event: Event | undefined;
	}>({
		open: false,
		event: undefined,
	});

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot='start'>
						<IonBackButton defaultHref='/apps/mock/Hospital'></IonBackButton>
					</IonButtons>
					<IonTitle>Map</IonTitle>
					<IonButtons slot='primary'>
						<IonButton
							color='primary'
							onClick={(e) =>
								setShowPopover({ open: true, event: e.nativeEvent })
							}>
							<IonIcon slot='icon-only' icon={options} />
						</IonButton>
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonPopover
				isOpen={showPopover.open}
				event={showPopover.event}
				onDidDismiss={(e) => setShowPopover({ open: false, event: undefined })}>
				<IonListHeader>Style</IonListHeader>
			</IonPopover>
			<IonContent fullscreen>
				<div ref={ref} style={{ width: "100%", height: "100%" }} />
			</IonContent>
		</IonPage>
	);
};

export default Map;
