/** @format */
import Amplify, { Analytics, API, graphqlOperation } from "aws-amplify";
import awsconfig from "../aws-exports";
import { from, pipe } from "rxjs";
import { pluck } from "rxjs/operators";
Amplify.configure(awsconfig);
export const scanMiddle = (store) => (next) => (action) => {
	Analytics.record({ name: action.type });
	switch (action.type) {
		case "scan/initScan":
			break;
	}

	return next(action);
};
