/** @format */

import React, { useEffect, useState } from "react";
import { Redirect, Route } from "react-router-dom";
import {
	IonApp,
	IonIcon,
	IonLabel,
	IonRouterOutlet,
	IonTabBar,
	IonTabButton,
	IonTabs,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import {
	informationCircle,
	ellipsisHorizontal,
	apps,
	school,
	mail,
} from "ionicons/icons";
import Scan from "./pages/Scan";
import DataScans from "./pages/DataScans";
import Map from "./pages/Map";
import Data from "./pages/Data";
import Info from "./pages/Info";
import More from "./pages/More";
import Login from "./pages/Login";
import Training from "./pages/Training";
import Mail from "./pages/Mail";
import Apps from "./pages/Apps";
import Course from "./pages/Course";
import MedHistory from "./pages/apps/MedHistory/MedHistory";
import Vaccine from "./pages/apps/Vaccine/Vaccine";
import Store from "./pages/apps/Store/Store";
import AppMock from "./pages/AppMock";
import AppDetail from "./pages/AppDetail";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import {
	Authenticator,
	SignIn,
	SignUp,
	ConfirmSignUp,
	Greetings,
} from "aws-amplify-react";

// Amplify
import {
	withAuthenticator,
	AmplifySignOut,
	AmplifyAuthenticator,
} from "@aws-amplify/ui-react";
import { Auth, Hub } from "aws-amplify";

import { useDispatch, useSelector } from "react-redux";
import { userReducer, authEvent, initUser } from "./redux/userSlice";
import AppSpreadsheet from "./pages/apps/spreadsheet/spreadsheet";

const App: React.FC = () => {
	const dispatch = useDispatch();
	const userSlice = useSelector(userReducer);
	useEffect(() => {
		dispatch(initUser(true));
	}, [dispatch]);
	return (
		<IonApp
			style={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}>
			<AmplifyAuthenticator>
				<IonReactRouter>
					<IonTabs>
						<IonRouterOutlet>
							<Route path='/apps' exact={true} component={Apps} />
							<Route path='/apps/mock/:app' exact={true} component={AppMock} />
							<Route
								path='/apps/detail/:app'
								exact={true}
								component={AppDetail}
							/>
							<Route
								path='/apps/medhistory'
								exact={true}
								component={MedHistory}
							/>
							<Route path='/apps/vaccine' exact={true} component={Vaccine} />

							<Route path='/training' exact={true} component={Training} />
							<Route path='/training/course' exact={true} component={Course} />
							<Route
								path='/apps/map/:lat/:lng/:zoom'
								exact={true}
								component={Map}
							/>
							<Route path='/info' component={Info} exact={true} />
							<Route path='/apps/store' component={Store} exact={true} />
							<Route path='/more' component={More} exact={true} />
							<Route path='/mail' exact={true} component={Mail} />
							<Route path='/data/scans' exact={true} component={DataScans} />
							<Route path='/scan' exact={true} component={Scan} />
							<Route
								path='/'
								render={() => <Redirect to='/info' />}
								exact={true}
							/>
						</IonRouterOutlet>
						<IonTabBar slot='bottom'>
							<IonTabButton tab='info' href='/info'>
								<IonIcon icon={informationCircle} />
								<IonLabel>ID</IonLabel>
							</IonTabButton>
							<IonTabButton tab='scan' href='/apps'>
								<IonIcon icon={apps} />
								<IonLabel>Apps</IonLabel>
							</IonTabButton>
							<IonTabButton tab='training' href='/training'>
								<IonIcon icon={school} />
								<IonLabel>Educación</IonLabel>
							</IonTabButton>
							<IonTabButton tab='mail' href='/mail'>
								<IonIcon icon={mail} />
								<IonLabel>Correo</IonLabel>
							</IonTabButton>
							<IonTabButton tab='more' href='/more'>
								<IonIcon icon={ellipsisHorizontal} />
								<IonLabel>Configuración</IonLabel>
							</IonTabButton>
						</IonTabBar>
					</IonTabs>
				</IonReactRouter>
			</AmplifyAuthenticator>
		</IonApp>
	);
};

export default App;
