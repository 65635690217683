/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const twilioSendSms = /* GraphQL */ `
  mutation TwilioSendSms($to: String!, $message: String!) {
    twilioSendSMS(to: $to, message: $message) {
      statusCode
      body
    }
  }
`;
export const rekCreateCollection = /* GraphQL */ `
  mutation RekCreateCollection($CollectionId: String!) {
    rekCreateCollection(CollectionId: $CollectionId) {
      CollectionArn
      FaceModelVersion
      StatusCode
    }
  }
`;
export const rekCreateProject = /* GraphQL */ `
  mutation RekCreateProject($ProjectName: String!) {
    rekCreateProject(ProjectName: $ProjectName) {
      ProjectArn
    }
  }
`;
export const rekDetectFaces = /* GraphQL */ `
  mutation RekDetectFaces($Bucket: String!, $Name: String!) {
    rekDetectFaces(Bucket: $Bucket, Name: $Name) {
      FaceDetails {
        AgeRange {
          High
          Low
        }
        Beard {
          Confidence
          Value
        }
        BoundingBox {
          Height
          Left
          Top
          Width
        }
        Confidence
        Emotions {
          Confidence
          Type
        }
        Eyeglasses {
          Confidence
          Value
        }
        EyesOpen {
          Confidence
          Value
        }
        Gender {
          Confidence
          Value
        }
        Landmarks {
          Type
          X
          Y
        }
        MouthOpen {
          Confidence
          Value
        }
        Mustache {
          Confidence
          Value
        }
        Pose {
          Pitch
          Roll
          Yaw
        }
        Quality {
          Brightness
          Sharpness
        }
        Smile {
          Confidence
          Value
        }
        Sunglasses {
          Confidence
          Value
        }
      }
      OrientationCorrection
    }
  }
`;
export const rekDetectFacesString = /* GraphQL */ `
  mutation RekDetectFacesString($Bucket: String!, $Name: String!) {
    rekDetectFacesString(Bucket: $Bucket, Name: $Name) {
      FaceDetails {
        AgeRange {
          High
          Low
        }
        Beard {
          Confidence
          Value
        }
        BoundingBox {
          Height
          Left
          Top
          Width
        }
        Confidence
        Emotions {
          Confidence
          Type
        }
        Eyeglasses {
          Confidence
          Value
        }
        EyesOpen {
          Confidence
          Value
        }
        Gender {
          Confidence
          Value
        }
        Landmarks {
          Type
          X
          Y
        }
        MouthOpen {
          Confidence
          Value
        }
        Mustache {
          Confidence
          Value
        }
        Pose {
          Pitch
          Roll
          Yaw
        }
        Quality {
          Brightness
          Sharpness
        }
        Smile {
          Confidence
          Value
        }
        Sunglasses {
          Confidence
          Value
        }
      }
      OrientationCorrection
    }
  }
`;
export const rekIndexFaces = /* GraphQL */ `
  mutation RekIndexFaces(
    $CollectionId: String!
    $DetectionAttributes: String
    $ExternalImageId: String
    $Bucket: String!
    $Name: String!
  ) {
    rekIndexFaces(
      CollectionId: $CollectionId
      DetectionAttributes: $DetectionAttributes
      ExternalImageId: $ExternalImageId
      Bucket: $Bucket
      Name: $Name
    ) {
      FaceRecords {
        Face {
          FaceId
          ImageId
          ExternalImageId
          Confidence
        }
      }
      OrientationCorrection
    }
  }
`;
export const createPerson = /* GraphQL */ `
  mutation CreatePerson(
    $input: CreatePersonInput!
    $condition: ModelPersonConditionInput
  ) {
    createPerson(input: $input, condition: $condition) {
      id
      rekognitionid
      rekognition
      scans {
        items {
          id
          personID
          lat
          lon
          rekognition
          testresult
          createdAt
          updatedAt
        }
        nextToken
      }
      FaceId
      ImageId
      ExternalImageId
      Confidence
      AgeRangeLow
      AgeRangeHigh
      BeardConfidence
      BeardValue
      BoundingBoxHeight
      BoundingBoxLeft
      BoundingBoxTop
      BoundingBoxWidth
      GenderConfidence
      GenderValue
      appdata {
        items {
          id
          appDataID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updatePerson = /* GraphQL */ `
  mutation UpdatePerson(
    $input: UpdatePersonInput!
    $condition: ModelPersonConditionInput
  ) {
    updatePerson(input: $input, condition: $condition) {
      id
      rekognitionid
      rekognition
      scans {
        items {
          id
          personID
          lat
          lon
          rekognition
          testresult
          createdAt
          updatedAt
        }
        nextToken
      }
      FaceId
      ImageId
      ExternalImageId
      Confidence
      AgeRangeLow
      AgeRangeHigh
      BeardConfidence
      BeardValue
      BoundingBoxHeight
      BoundingBoxLeft
      BoundingBoxTop
      BoundingBoxWidth
      GenderConfidence
      GenderValue
      appdata {
        items {
          id
          appDataID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deletePerson = /* GraphQL */ `
  mutation DeletePerson(
    $input: DeletePersonInput!
    $condition: ModelPersonConditionInput
  ) {
    deletePerson(input: $input, condition: $condition) {
      id
      rekognitionid
      rekognition
      scans {
        items {
          id
          personID
          lat
          lon
          rekognition
          testresult
          createdAt
          updatedAt
        }
        nextToken
      }
      FaceId
      ImageId
      ExternalImageId
      Confidence
      AgeRangeLow
      AgeRangeHigh
      BeardConfidence
      BeardValue
      BoundingBoxHeight
      BoundingBoxLeft
      BoundingBoxTop
      BoundingBoxWidth
      GenderConfidence
      GenderValue
      appdata {
        items {
          id
          appDataID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createAppData = /* GraphQL */ `
  mutation CreateAppData(
    $input: CreateAppDataInput!
    $condition: ModelAppDataConditionInput
  ) {
    createAppData(input: $input, condition: $condition) {
      id
      appDataID
      person {
        id
        rekognitionid
        rekognition
        scans {
          nextToken
        }
        FaceId
        ImageId
        ExternalImageId
        Confidence
        AgeRangeLow
        AgeRangeHigh
        BeardConfidence
        BeardValue
        BoundingBoxHeight
        BoundingBoxLeft
        BoundingBoxTop
        BoundingBoxWidth
        GenderConfidence
        GenderValue
        appdata {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateAppData = /* GraphQL */ `
  mutation UpdateAppData(
    $input: UpdateAppDataInput!
    $condition: ModelAppDataConditionInput
  ) {
    updateAppData(input: $input, condition: $condition) {
      id
      appDataID
      person {
        id
        rekognitionid
        rekognition
        scans {
          nextToken
        }
        FaceId
        ImageId
        ExternalImageId
        Confidence
        AgeRangeLow
        AgeRangeHigh
        BeardConfidence
        BeardValue
        BoundingBoxHeight
        BoundingBoxLeft
        BoundingBoxTop
        BoundingBoxWidth
        GenderConfidence
        GenderValue
        appdata {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteAppData = /* GraphQL */ `
  mutation DeleteAppData(
    $input: DeleteAppDataInput!
    $condition: ModelAppDataConditionInput
  ) {
    deleteAppData(input: $input, condition: $condition) {
      id
      appDataID
      person {
        id
        rekognitionid
        rekognition
        scans {
          nextToken
        }
        FaceId
        ImageId
        ExternalImageId
        Confidence
        AgeRangeLow
        AgeRangeHigh
        BeardConfidence
        BeardValue
        BoundingBoxHeight
        BoundingBoxLeft
        BoundingBoxTop
        BoundingBoxWidth
        GenderConfidence
        GenderValue
        appdata {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createScan = /* GraphQL */ `
  mutation CreateScan(
    $input: CreateScanInput!
    $condition: ModelScanConditionInput
  ) {
    createScan(input: $input, condition: $condition) {
      id
      personID
      lat
      lon
      rekognition
      testresult
      person {
        id
        rekognitionid
        rekognition
        scans {
          nextToken
        }
        FaceId
        ImageId
        ExternalImageId
        Confidence
        AgeRangeLow
        AgeRangeHigh
        BeardConfidence
        BeardValue
        BoundingBoxHeight
        BoundingBoxLeft
        BoundingBoxTop
        BoundingBoxWidth
        GenderConfidence
        GenderValue
        appdata {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateScan = /* GraphQL */ `
  mutation UpdateScan(
    $input: UpdateScanInput!
    $condition: ModelScanConditionInput
  ) {
    updateScan(input: $input, condition: $condition) {
      id
      personID
      lat
      lon
      rekognition
      testresult
      person {
        id
        rekognitionid
        rekognition
        scans {
          nextToken
        }
        FaceId
        ImageId
        ExternalImageId
        Confidence
        AgeRangeLow
        AgeRangeHigh
        BeardConfidence
        BeardValue
        BoundingBoxHeight
        BoundingBoxLeft
        BoundingBoxTop
        BoundingBoxWidth
        GenderConfidence
        GenderValue
        appdata {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteScan = /* GraphQL */ `
  mutation DeleteScan(
    $input: DeleteScanInput!
    $condition: ModelScanConditionInput
  ) {
    deleteScan(input: $input, condition: $condition) {
      id
      personID
      lat
      lon
      rekognition
      testresult
      person {
        id
        rekognitionid
        rekognition
        scans {
          nextToken
        }
        FaceId
        ImageId
        ExternalImageId
        Confidence
        AgeRangeLow
        AgeRangeHigh
        BeardConfidence
        BeardValue
        BoundingBoxHeight
        BoundingBoxLeft
        BoundingBoxTop
        BoundingBoxWidth
        GenderConfidence
        GenderValue
        appdata {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createApp = /* GraphQL */ `
  mutation CreateApp(
    $input: CreateAppInput!
    $condition: ModelAppConditionInput
  ) {
    createApp(input: $input, condition: $condition) {
      id
      name
      hospital {
        id
        name
        prescriptions {
          id
          name
          date
          validuntil
        }
      }
      marketplace {
        id
        type
        name
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateApp = /* GraphQL */ `
  mutation UpdateApp(
    $input: UpdateAppInput!
    $condition: ModelAppConditionInput
  ) {
    updateApp(input: $input, condition: $condition) {
      id
      name
      hospital {
        id
        name
        prescriptions {
          id
          name
          date
          validuntil
        }
      }
      marketplace {
        id
        type
        name
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteApp = /* GraphQL */ `
  mutation DeleteApp(
    $input: DeleteAppInput!
    $condition: ModelAppConditionInput
  ) {
    deleteApp(input: $input, condition: $condition) {
      id
      name
      hospital {
        id
        name
        prescriptions {
          id
          name
          date
          validuntil
        }
      }
      marketplace {
        id
        type
        name
      }
      createdAt
      updatedAt
    }
  }
`;
export const createSign = /* GraphQL */ `
  mutation CreateSign(
    $input: CreateSignInput!
    $condition: ModelSignConditionInput
  ) {
    createSign(input: $input, condition: $condition) {
      id
      establishment
      message
      lat
      lon
      createdAt
      updatedAt
    }
  }
`;
export const updateSign = /* GraphQL */ `
  mutation UpdateSign(
    $input: UpdateSignInput!
    $condition: ModelSignConditionInput
  ) {
    updateSign(input: $input, condition: $condition) {
      id
      establishment
      message
      lat
      lon
      createdAt
      updatedAt
    }
  }
`;
export const deleteSign = /* GraphQL */ `
  mutation DeleteSign(
    $input: DeleteSignInput!
    $condition: ModelSignConditionInput
  ) {
    deleteSign(input: $input, condition: $condition) {
      id
      establishment
      message
      lat
      lon
      createdAt
      updatedAt
    }
  }
`;
