/** @format */

import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
	name: "user",
	initialState: {
		init: false,
		isloggedin: true,
		message: "User",
		auth: {},
	},
	reducers: {
		initUser: (state, action) => {
			state.init = true;
		},
		authEvent: (state, action) => {
			state.auth = action.payload;
		},
		setIsLoggedIn: (state, action) => {
			state.isloggedin = action.payload;
		},
	},
});

export const userReducer = (state) => state.user;
export const { initUser, authEvent } = userSlice.actions;

export default userSlice.reducer;
