/** @format */

import { configureStore } from "@reduxjs/toolkit";

// Redux
import scanSlice from "./scanSlice";
import mapSlice from "./mapSlice";
import userSlice from "./userSlice";
import appsSlice from "./appsSlice";
import { appsMiddle } from "./appsMiddle";
import { userMiddle } from "./userMiddle";
import { scanMiddle } from "./scanMiddle";
import { mapMiddle } from "./mapMiddle";

export default configureStore({
	reducer: {
		scan: scanSlice,
		map: mapSlice,
		user: userSlice,
		apps: appsSlice,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware()
			.prepend(scanMiddle, mapMiddle, userMiddle, appsMiddle)
			.concat(),
	devTools: true,
});
