/** @format */
import Amplify, { Analytics, API, graphqlOperation } from "aws-amplify";
import awsconfig from "../aws-exports";
import { from, pipe } from "rxjs";
import { pluck } from "rxjs/operators";
import { Auth, Hub } from "aws-amplify";
Amplify.configure(awsconfig);
export const userMiddle = (store) => (next) => (action) => {
	const setUserCredentials = () => {
		Auth.currentUserInfo().then((ev) => {
			store.dispatch({ type: "user/authEvent", payload: ev });
		});
		Auth.currentAuthenticatedUser()
			.then((ev) => {
				store.dispatch({
					type: "user/setIsLoggedIn",
					payload: true,
				});
			})
			.catch((err) => {
				store.dispatch({
					type: "user/setIsLoggedIn",
					payload: false,
				});
			});
	};
	Analytics.record({ name: action.type, action: action.payload });
	switch (action.type) {
		case "user/initUser":
			Hub.listen("auth", (data) => {
				setUserCredentials();
			});
			setUserCredentials();

			break;
	}

	return next(action);
};
