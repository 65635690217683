/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_appsync_graphqlEndpoint": "https://xuydqmyyl5asbcyvd6shwjts2q.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-z5pqhst2mngirmjrdd22tjzuxe",
    "aws_cloud_logic_custom": [
        {
            "name": "302external",
            "endpoint": "https://qsog637maf.execute-api.us-west-2.amazonaws.com/dev",
            "region": "us-west-2"
        }
    ],
    "aws_cognito_identity_pool_id": "us-west-2:41f5ef38-1499-46b9-9ac2-f61d2e264be6",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_VaUqnNVyb",
    "aws_user_pools_web_client_id": "5tkpau2vsfjedkdd14kbjkt5ns",
    "oauth": {},
    "aws_user_files_s3_bucket": "302privascan234537-dev",
    "aws_user_files_s3_bucket_region": "us-west-2",
    "aws_mobile_analytics_app_id": "eb20c6e4351a4881a65d7d56ea92a4f9",
    "aws_mobile_analytics_app_region": "us-west-2"
};


export default awsmobile;
