/** @format */

import { createSlice } from "@reduxjs/toolkit";

const scanSlice = createSlice({
	name: "scan",
	initialState: {
		init: false,
		message: "Scan",
		geolocation: {},
	},
	reducers: {
		initScan: (state, action) => {
			state.init = true;
		},
		setGeolocation: (state, action) => {
			state.geolocation = action.payload;
		},
	},
});

export const scanReducer = (state) => state.scan;
export const { initScan, setGeolocation } = scanSlice.actions;

export default scanSlice.reducer;
