/** @format */

import React, { useEffect, useState } from "react";
import {
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar,
	IonList,
	IonItem,
	IonLabel,
	IonIcon,
	IonCol,
	IonGrid,
	IonRow,
	IonFab,
	IonFabButton,
	IonButton,
	IonModal,
	IonListHeader,
	IonAvatar,
	IonBadge,
} from "@ionic/react";
import { add } from "ionicons/icons";
import "./Apps.css";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { appsReducer, initApps, setToggleApp } from "../redux/appsSlice";

const Apps: React.FC = () => {
	const [showModal, setShowModal] = useState(false);
	const dispatch = useDispatch();
	const appsSlice = useSelector(appsReducer);
	useEffect(() => {
		dispatch(initApps(true));
	}, [dispatch]);
	const app = appsSlice.appRoot;
	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonTitle>Apps</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent fullscreen>
				{/* <IonHeader collapse='condense'>
					<IonToolbar>
						<IonTitle size='large'>Apps</IonTitle>
					</IonToolbar>
				</IonHeader> */}
				{/* <img src='/assets/appicons/hospital_01.png'></img>
				<img src='/assets/appicons/escuela_01.png'></img>
				<img src='/assets/appicons/comercio_01.png'></img>
				<img src='/assets/appicons/empleo_01.png'></img>
				<img src='/assets/appicons/hogar_01.png'></img>
				<img src='/assets/appicons/amigos_01.png'></img> */}
				{/* <img src='/assets/appicons/top_01.png' width='100%'></img> */}
				<IonGrid>
					<IonRow>
						<Link to='/apps/mock/Hospital'>
							<img src='/assets/appicons/salud_04.jpg'></img>
						</Link>
					</IonRow>
					<IonRow>
						<Link to='/apps/mock/Comercio'>
							<img src='/assets/appicons/comercio_04.jpg'></img>
						</Link>
					</IonRow>
					<IonRow>
						<Link to='/apps/mock/Educación'>
							<img src='/assets/appicons/educacion_04.jpg'></img>
						</Link>
					</IonRow>

					<IonRow>
						<Link to='/apps/mock/Hogar'>
							<img src='/assets/appicons/hogar_04.jpg'></img>
						</Link>
					</IonRow>
					<IonRow>
						<Link to='/apps/mock/Empresa'>
							<img src='/assets/appicons/empresa_04.jpg'></img>
						</Link>
					</IonRow>
					{/* <IonRow>
						<img src='/assets/appicons/comunidad_04.jpg'></img>
					</IonRow> */}
					<IonRow>
						<Link to='/apps/mock/Oportunidades'>
							<img src='/assets/appicons/oportunidades_04.jpg'></img>
						</Link>
					</IonRow>
				</IonGrid>

				{/* {Object.keys(app.root).map((key, item) => (
					<IonList key={key}>
						<IonItem routerLink={"/apps/mock/" + key}>{key}</IonItem>
					</IonList>
				))} */}
				{/* {grid(appsSlice)}
				{Fab(setShowModal)}
				{StoreModal(showModal, setShowModal, appsSlice, dispatch)} */}
			</IonContent>
		</IonPage>
	);
};

export default Apps;

// function StoreModal(
// 	showModal: boolean,
// 	setShowModal: React.Dispatch<React.SetStateAction<boolean>>,
// 	appsSlice: any,
// 	dispatch: any
// ) {
// 	return (
// 		<IonModal isOpen={showModal} cssClass='my-custom-class'>
// 			<IonHeader>
// 				<IonToolbar>
// 					<IonButton
// 						onClick={() => setShowModal(false)}
// 						slot='start'
// 						fill='clear'>
// 						X Cerrar
// 					</IonButton>
// 					<IonTitle>Tienda de Apps</IonTitle>
// 				</IonToolbar>
// 			</IonHeader>
// 			<IonContent>
// 				{appsSlice.apps.map((category: any) => (
// 					<IonList key={category.name}>
// 						<IonListHeader>{category.name}</IonListHeader>
// 						{category.apps.map((app: any) => (
// 							<IonItem
// 								disabled={app.disabled}
// 								key={app.name}
// 								onClick={() =>
// 									dispatch(
// 										setToggleApp({
// 											category: category.name,
// 											app: app.name,
// 										})
// 									)
// 								}>
// 								<IonAvatar slot='start'>
// 									<img
// 										src={"assets/appicons/" + app.icon}
// 										style={{ width: "20vw" }}
// 										alt={app.name}></img>
// 								</IonAvatar>
// 								<IonLabel>{app.name}</IonLabel>
// 								<IonBadge slot='end'>
// 									{app.active ? "Remover" : "Añadir"}
// 								</IonBadge>
// 							</IonItem>
// 						))}
// 					</IonList>
// 				))}
// 			</IonContent>
// 		</IonModal>
// 	);
// }

// function Fab(setShowModal: React.Dispatch<React.SetStateAction<boolean>>) {
// 	return (
// 		<IonFab horizontal='end' vertical='bottom' slot='fixed'>
// 			<IonFabButton onClick={() => setShowModal(true)}>
// 				<IonIcon icon={add}></IonIcon>
// 			</IonFabButton>
// 		</IonFab>
// 	);
// }

// function grid(appsSlice: any) {
// 	return (
// 		<IonGrid className='ion-padding-bottom'>
// 			{appsSlice.apps.map((category: any) => (
// 				<IonRow key={category.name} className='ion-justify-content-start'>
// 					<IonListHeader>{category.name}</IonListHeader>
// 					{category.apps.map((app: any) => (
// 						<React.Fragment key={app.name}>
// 							{app.active ? (
// 								<IonCol size='4' className='center'>
// 									<Link to={app.link}>
// 										<img
// 											src={"assets/appicons/" + app.icon}
// 											style={{ width: "20vw" }}
// 											alt={app.name}></img>
// 									</Link>

// 									{app.name}
// 								</IonCol>
// 							) : null}
// 						</React.Fragment>
// 					))}
// 				</IonRow>
// 			))}
// 		</IonGrid>
// 	);
// }
