/** @format */

import React, { useState } from "react";
import {
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar,
	IonList,
	IonItem,
	IonLabel,
	IonBackButton,
	IonButtons,
	IonListHeader,
	IonInput,
	IonSegment,
	IonSegmentButton,
	IonFab,
	IonFabButton,
	IonIcon,
	IonActionSheet,
	IonButton,
	IonModal,
	IonItemDivider,
} from "@ionic/react";
import {
	add,
	atCircle,
	caretForwardCircle,
	heart,
	scan,
	share,
	trash,
} from "ionicons/icons";

interface NewProduct {
	productName: string | undefined | null;
	productDescription: string | undefined | null;
	productPrice: string | number | undefined | null;
}

const MedHistory: React.FC = () => {
	const [mode, setMode] = useState<string | undefined>("inventario");
	const [addInventory, setAddInventory] = useState<boolean>(false);
	const [product, setProduct] = useState<NewProduct>({
		productName: "",
		productDescription: "",
		productPrice: 0,
	});
	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot='start'>
						<IonBackButton defaultHref='/apps'></IonBackButton>
					</IonButtons>
					<IonTitle>Mi Tienda</IonTitle>
				</IonToolbar>
				<IonSegment value={mode} onIonChange={(e) => setMode(e.detail.value)}>
					<IonSegmentButton value='desplegar'>
						<IonLabel>Desplegar</IonLabel>
					</IonSegmentButton>
					<IonSegmentButton value='inventario'>
						<IonLabel>Inventario</IonLabel>
					</IonSegmentButton>
				</IonSegment>
			</IonHeader>
			<IonContent fullscreen>
				<IonHeader collapse='condense'>
					<IonToolbar>
						<IonTitle size='large'>Mi Tienda</IonTitle>
					</IonToolbar>
				</IonHeader>
				<IonList>
					<IonItem>
						<IonLabel></IonLabel>
					</IonItem>
				</IonList>
				<IonFab
					horizontal='end'
					vertical='bottom'
					slot='fixed'
					hidden={mode !== "inventario"}>
					<IonFabButton>
						<IonIcon
							icon={add}
							onClick={(e) => setAddInventory(true)}></IonIcon>
					</IonFabButton>
				</IonFab>
				<IonModal isOpen={addInventory}>
					<IonHeader>
						<IonToolbar>
							<IonButton
								onClick={() => setAddInventory(false)}
								slot='start'
								fill='clear'>
								X Cerrar
							</IonButton>
							<IonTitle>Tienda de Apps</IonTitle>
						</IonToolbar>
					</IonHeader>
					<IonContent>
						<IonList>
							<IonItemDivider>Producto</IonItemDivider>
							<IonItem>
								<IonLabel position='floating'>Nombre del Producto</IonLabel>
								<IonInput
									onIonChange={(e) =>
										setProduct({ ...product, productName: e.detail.value })
									}
									value={product.productName}></IonInput>
							</IonItem>
							<IonItem>
								<IonLabel position='floating'>
									Descripcion del Producto
								</IonLabel>
								<IonInput
									onIonChange={(e) =>
										setProduct({
											...product,
											productDescription: e.detail.value,
										})
									}
									value={product.productDescription}></IonInput>
							</IonItem>
							<IonItem>
								<IonLabel position='floating'>Precio del Producto</IonLabel>
								<IonInput
									type='number'
									onIonChange={(e) =>
										setProduct({ ...product, productPrice: e.detail.value })
									}
									value={product.productPrice}></IonInput>
							</IonItem>
						</IonList>
						<IonButton
							expand='block'
							fill='outline'
							onClick={(e) => console.log(product)}>
							Anadir Producto
						</IonButton>
					</IonContent>
				</IonModal>
			</IonContent>
		</IonPage>
	);
};

export default MedHistory;
