/** @format */

import { createSlice } from "@reduxjs/toolkit";

const appsSlice = createSlice({
	name: "apps",
	initialState: {
		init: false,
		apps: [
			{
				name: "Salud",
				apps: [
					{
						name: "Vacuna",
						icon: "vacuna_01.png",
						active: true,
						link: "/apps/vaccine",
						disabled: true,
					},
					{
						name: "Prueba",
						icon: "scan_01.png",
						active: true,
						link: "/scan",
					},
					{
						name: "Expediente",
						icon: "documentos_01.png",
						active: true,
						link: "/apps/medhistory",
					},
					{
						name: "Scans",
						icon: "network_01.png",
						active: true,
						link: "/data/scans",
					},
				],
			},
			{
				name: "Nutricion",
				apps: [
					{
						name: "Bodega",
						icon: "shopping_01.png",
						active: true,
						link: "/map",
					},
					{
						name: "Recetas",
						icon: "recetas_01.png",
						active: true,
						link: "/map",
					},
				],
			},
			{
				name: "Comunidad",
				apps: [
					{
						name: "Clima",
						icon: "weather_01.png",
						active: false,
						disabled: true,
						link: "/map",
					},
					{
						name: "Noticias",
						icon: "news_01.png",
						active: false,
						disabled: true,
						link: "/map",
					},
					{
						name: "Alertas",
						icon: "alertas_01.png",
						active: true,
						disabled: true,
						link: "/map",
					},
					{
						name: "Foro",
						icon: "forum_01.png",
						active: false,
						disabled: true,
						link: "/map",
					},
					{
						name: "Red Social",
						icon: "network_01.png",
						active: false,
						disabled: true,
						link: "/map",
					},
					{
						name: "Ninos",
						icon: "star_01.png",
						active: false,
						disabled: true,
						link: "/map",
					},
				],
			},
			{
				name: "Finanzas",
				apps: [
					{
						name: "Cuentas",
						icon: "wallet_01.png",
						active: false,
						link: "/map",
					},
					{
						name: "Transferencias",
						icon: "exchange_01.png",
						active: false,
						link: "/map",
					},
					{
						name: "Empresa",
						icon: "calc_01.png",
						active: true,
						link: "/map",
					},
					{
						name: "Metas",
						icon: "target_01.png",
						active: false,
						link: "/map",
					},
				],
			},
			{
				name: "Tiendas",
				apps: [
					{
						name: "Tienda",
						icon: "store_01.png",
						active: false,
						link: "apps/store",
					},
				],
			},
			{
				name: "Mapas",
				apps: [
					{
						name: "Honduras",
						icon: "pin_01.png",
						active: false,
						link: "/apps/map/15/-87/7",
					},
					{
						name: "El Paraiso",
						icon: "pin_01.png",
						active: true,
						link: "/apps/map/14/-86.5/10",
					},
					{
						name: "San Diego",
						icon: "pin_01.png",
						active: true,
						link: "/apps/map/14.0525172/-86.453873/15",
					},
					{
						name: "Jutiapa",
						icon: "pin_01.png",
						active: true,
						link: "/apps/map/13.985/-86.399/15",
					},
					{
						name: "Tegucigalpa",
						icon: "pin_01.png",
						active: false,
						link: "/apps/map/14.1/-87.2/12",
					},
					{
						name: "San Pedro",
						icon: "pin_01.png",
						active: false,
						link: "/apps/map/15.52/-88/12",
					},
					{
						name: "La Ceiba",
						icon: "pin_01.png",
						active: false,
						link: "/apps/map/15.78/-86.78/13",
					},
				],
			},
		],
		appRoot: {
			root: {
				Empresa: {
					Herramientas: [
						{
							Nombre: "Venta",
							Icon: "venta.jpg",
							Position: {
								Priority: "Banner",
								Order: 1,
							},
						},
						{
							Nombre: "Inventario",
							Icon: "inventario.jpg",
							Position: {
								Priority: "Destacadas",
								Order: 1,
							},
						},
						{
							Nombre: "Facturas",
							Icon: "facturas.jpg",
							Position: {
								Priority: "Destacadas",
								Order: 1,
							},
						},
						{
							Nombre: "Tienda",
							Icon: "tienda.jpg",
							Position: {
								Priority: "Destacadas",
								Order: 1,
							},
						},
						{
							Nombre: "Clientes",
							Icon: "clientes.jpg",
							Position: {
								Priority: "Destacadas",
								Order: 1,
							},
						},

						{
							Nombre: "Empleados",
							Icon: "empleados.jpg",
							Position: {
								Priority: "Destacadas",
								Order: 1,
							},
						},
						{
							Nombre: "Portal",
							Icon: "portal.jpg",
							Position: {
								Priority: "Destacadas",
								Order: 1,
							},
						},
						{
							Nombre: "Contabilidad",
							Icon: "contabilidad2.jpg",
							Position: {
								Priority: "Destacadas",
								Order: 1,
							},
						},
						{
							Nombre: "Seguro",
							Icon: "seguro.jpg",
							Position: {
								Priority: "Destacadas",
								Order: 1,
							},
						},
					],
					Bancos: [
						{
							Nombre: "Banco Atlantida",
							Icon: "bancoatlantida.jpg",
							Position: {
								Priority: "Destacadas",
								Order: 1,
							},
						},
						{
							Nombre: "Ficohsa",
							Icon: "ficohsa.jpg",
							Position: {
								Priority: "Destacadas",
								Order: 1,
							},
						},
						{
							Nombre: "Davivienda",
							Icon: "davivienda.jpg",
							Position: {
								Priority: "Destacadas",
								Order: 1,
							},
						},
						{
							Nombre: "Banpais",
							Icon: "banpais.jpg",
							Position: {
								Priority: "Destacadas",
								Order: 1,
							},
						},
					],
				},
				Educación: {
					Cursos: [
						{
							Nombre: "Panadería",
							Icon: "panaderia.jpg",
							Position: {
								Priority: "Banner",
								Order: 1,
							},
						},
						{
							Nombre: "Albañilería",
							Icon: "albanileria.jpg",
							Position: {
								Priority: "Destacadas",
								Order: 1,
							},
						},
						{
							Nombre: "Camionero",
							Icon: "camionero.jpg",
							Position: {
								Priority: "Destacadas",
								Order: 1,
							},
						},
						{
							Nombre: "Contabilidad",
							Icon: "contabilidad.jpg",
							Position: {
								Priority: "Destacadas",
								Order: 1,
							},
						},
						{
							Nombre: "Cultivos",
							Icon: "cultivos.jpg",
							Position: {
								Priority: "Destacadas",
								Order: 1,
							},
						},
						{
							Nombre: "Ganadería",
							Icon: "ganaderia.jpg",
							Position: {
								Priority: "Destacadas",
								Order: 1,
							},
						},
						{
							Nombre: "Carpintería",
							Icon: "carpinteria.jpg",
							Position: {
								Priority: "Destacadas",
								Order: 1,
							},
						},
						{
							Nombre: "Inglés",
							Icon: "ingles.jpg",
							Position: {
								Priority: "Destacadas",
								Order: 1,
							},
						},
						{
							Nombre: "Computación",
							Icon: "computacion.jpg",
							Position: {
								Priority: "Destacadas",
								Order: 1,
							},
						},
					],
				},
				Oportunidades: {
					Empleos: {
						Plazas: [
							{
								Nombre: "Maestro de Obra",
								Empresa: "Constructora ABC",
							},
							{
								Nombre: "Mecanico Automotriz",
								Empresa: "Garage Shop",
							},
							{
								Nombre: "Secreataria Bilingue",
								Empresa: "Walmart",
							},
							{
								Nombre: "Chef",
								Empresa: "Restaurante",
							},
							{
								Nombre: "Chofer de Bus",
								Empresa: "Hedman Alas",
							},
						],
					},
					Currículum: {},
				},
				Hogar: {
					Recetas: {
						Banner: [
							{
								Nombre: "Sandwich de Pollo",
								Icon: "sandwich_01.jpg",
							},
						],
						Destacadas: [
							{
								Nombre: "Fajitas de Res",
								Icon: "fajitas.jpg",
							},
							{
								Nombre: "Ensalada Zanahoria",
								Icon: "zanahorias.jpg",
							},
							{
								Nombre: "Hamburgesa",
								Icon: "hamburgesa.jpg",
							},
							{
								Nombre: "Ensalada Vegetales",
								Icon: "ensalada.jpg",
							},
							{
								Nombre: "Spaghetti Alfredo",
								Icon: "spaghetti.jpg",
							},
							{
								Nombre: "Pollo Frito",
								Icon: "pollo.jpg",
							},
						],
					},
					Despensa: {
						Ingredientes: [
							{
								Nombre: "Frijoles",
								Cantidad: "5lb",
							},
							{
								Nombre: "Arroz",
								Cantidad: "10lb",
							},
							{
								Nombre: "Leche",
								Cantidad: "1l",
							},
							{
								Nombre: "Pan Molde",
								Cantidad: "10",
							},
							{
								Nombre: "Tomates",
								Cantidad: "11u",
							},
							{
								Nombre: "Lechuga",
								Cantidad: "5lb",
							},
							{
								Nombre: "Filete de res",
								Cantidad: "8lb",
							},
							{
								Nombre: "Pollo",
								Cantidad: "10lb",
							},
							{
								Nombre: "Carne de Hamburgesa",
								Cantidad: "20lb",
							},
							{
								Nombre: "Papas",
								Cantidad: "16lb",
							},
						],
					},
				},
				Comercio: {
					Tiendas: {
						Banner: [
							{
								Nombre: "Walmart",
								Icon: "walmart.jpg",
							},
						],
						Destacadas: [
							{
								Nombre: "Jetstereo",
								Icon: "jetstereo.jpg",
							},
							{
								Nombre: "Diunsa",
								Icon: "diunsa.jpg",
							},
							{
								Nombre: "Lady Lee",
								Icon: "ladylee.jpg",
							},
							{
								Nombre: "Kielsa",
								Icon: "kielsa.jpg",
							},
							{
								Nombre: "La Colonia",
								Icon: "lacolonia.jpg",
							},
							{
								Nombre: "Pizzahut",
								Icon: "pizzahut.jpg",
							},
						],
						List: [
							{
								Nombre: "Restaurante",
								Icon: "restaurant.jpg",
							},
							{
								Nombre: "Zapateria",
								Icon: "shoes.jpg",
							},
							{
								Nombre: "Barberia",
								Icon: "barberia.jpg",
							},
							{
								Nombre: "Boutique",
								Icon: "boutique.jpg",
							},
							{
								Nombre: "Frutas",
								Icon: "frutas.jpg",
							},
							{
								Nombre: "Carniceria",
								Icon: "carniceria.jpg",
							},
							{
								Nombre: "Reparacion de Telefono Celular",
								Icon: "cell.jpg",
							},
							{
								Nombre: "Ferreteria",
								Icon: "ferreteria.jpg",
							},
							{
								Nombre: "Hotel",
								Icon: "hotel.jpg",
							},
							{
								Nombre: "Taller de Mecanica",
								Icon: "auto.jpg",
							},
						],
					},
				},
				Agricultura: {
					Mapas: {},
					Propiedades: {},
					Producto: {},
				},
				Comunidad: {
					Amigos: {},
					Lideres: {},
					Tramites: {},
					Noticias: {},
				},
				Cocina: {
					Despensa: {},
					Recetas: {},
				},
				Banco: {
					Cuentas: {},
					Prestamos: {},
				},
				Escuela: {
					CicloComun: {},
					Universidad: {},
					Salud: {},
					Idiomas: {},
					Agricultura: {},
					Negocios: {},
					Alimentacion: {},
				},
				Hospital: {
					Vacuna: {
						Proxima: {
							Fecha: "3/1/2022",
							Tipo: "Sanofi SARS-COV-2",
							Suplidor: "COVAX",
						},
						Historial: [
							{
								Fecha: "4/10/2021",
								Tipo: "Influenza",
								Suplidor: "GlaxoSmithKline",
								Icon: "gsk",
							},
							{
								Fecha: "7/1/2021",
								Tipo: "SARS-COVID-2",
								Suplidor: "Sanofi / COVAX",
								Icon: "sanofi",
							},
							{
								Fecha: "10/8/2021",
								Tipo: "SARS-COVID-3",
								Suplidor: "Johnson & Johnson",
								Icon: "jj",
							},
							{
								Fecha: "1/23/2022",
								Tipo: "SARS-COVID-2",
								Suplidor: "Sanofi / COVAX",
								Icon: "sanofi",
							},
						],
					},
					Farmacia: {
						Recetas: [
							{
								Nombre: "Lisinopril (Prinivil)",
								Unidad: "10mg",
								Aplicacion: "Oral",
								Frecuencia: "1 Por la manana cada dia",
							},
							{
								Nombre: "Metformin (Glucophage)",
								Unidad: "500mg",
								Aplicacion: "Oral",
								Frecuencia: "1 manana, 1 noche",
							},
						],
					},
					Consulta: {
						Citas: [
							{
								Nombre: "Vacuna SARSCOV2",
								Fecha: "3/1/2022",
								Hora: "3PM",
							},
							{
								Nombre: "Examen médico anual",
								Fecha: "2/18/2022",
								Hora: "8AM",
								Locacion: "Centro Medico Principal",
								Notas: "En ayuno",
							},
						],
					},
					Expediente: {},
				},
				Mercado: {
					Tiendas: {
						Tienda: {
							Productos: {},
							Carrito: {},
						},
					},
				},
			},
		},
	},
	reducers: {
		initApps: (state, action) => {
			state.init = true;
			console.log("Init app slice", action);
		},
		setToggleApp: (state, action) => {
			state.apps
				.find(({ name }) => name === action.payload.category)
				.apps.find(
					({ name }) => name === action.payload.app
				).active = !state.apps
				.find(({ name }) => name === action.payload.category)
				.apps.find(({ name }) => name === action.payload.app).active;
		},
	},
});

export const appsReducer = (state) => state.apps;
export const { initApps, setToggleApp } = appsSlice.actions;

export default appsSlice.reducer;
