/** @format */

import React from "react";
import {
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar,
	IonList,
	IonItem,
	IonLabel,
	IonIcon,
	IonBackButton,
	IonButtons,
} from "@ionic/react";
import ExploreContainer from "../components/ExploreContainer";
import { scan } from "ionicons/icons";
import YouTube from "react-youtube";

const Course: React.FC = () => {
	const opts = {
		width: "100%",
	};
	const onReady = () => {
		console.log("Ready");
	};
	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot='start'>
						<IonBackButton defaultHref='/training'></IonBackButton>
					</IonButtons>
					<IonTitle>Course</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent fullscreen>
				<IonHeader collapse='condense'>
					<IonToolbar>
						<IonTitle size='large'>Course</IonTitle>
					</IonToolbar>
				</IonHeader>
				<YouTube videoId='9_F9UBNE9sc' opts={opts} onReady={onReady} />;
			</IonContent>
		</IonPage>
	);
};

export default Course;
