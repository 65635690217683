/** @format */

import React from "react";
import {
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar,
	IonList,
	IonItem,
	IonLabel,
	IonIcon,
	IonText,
	IonNote,
	IonBackButton,
	IonButtons,
} from "@ionic/react";
import ExploreContainer from "../components/ExploreContainer";
import { checkmark, scan } from "ionicons/icons";

const Mail: React.FC = () => {
	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonTitle>Mail</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent fullscreen>
				<IonHeader collapse='condense'>
					<IonToolbar>
						<IonTitle size='large'>Mail</IonTitle>
					</IonToolbar>
				</IonHeader>
				<IonList>
					<IonItem>
						<IonIcon icon={checkmark} color='primary' slot='start'></IonIcon>
						<IonLabel>
							<h2>testresults@testing.com</h2>
							<h3>Your test results are ready. </h3>
							<p>
								Congratulations. You have tested negative on your 9/14 COVID-19
								test
							</p>
						</IonLabel>
						<IonNote slot='end'>
							11:32 AM<br></br>9/15/2020
						</IonNote>
					</IonItem>
				</IonList>
			</IonContent>
		</IonPage>
	);
};

export default Mail;
