/** @format */
import Amplify, { API, graphqlOperation, Analytics } from "aws-amplify";
import awsconfig from "../aws-exports";
import { from, pipe } from "rxjs";
import { pluck } from "rxjs/operators";
import { listScans } from "../graphql/queries";
Amplify.configure(awsconfig);
export const appsMiddle = (store) => (next) => (action) => {
	Analytics.record({ name: action.type });
	switch (action.type) {
		case "apps/initApp":
			console.log("initApp middleware");

			break;
		default:
	}

	return next(action);
};
