/** @format */
import Amplify, { API, graphqlOperation, Analytics } from "aws-amplify";
import awsconfig from "../aws-exports";
import { from, pipe } from "rxjs";
import { pluck } from "rxjs/operators";
import { listScans } from "../graphql/queries";
Amplify.configure(awsconfig);
export const mapMiddle = (store) => (next) => (action) => {
	Analytics.record({ name: action.type });
	switch (action.type) {
		case "map/initMap":
			console.log("initMap middleware");
			API.graphql(
				graphqlOperation(
					`
                    query ListScans {
                        listScans(limit: 1000) {
                            items {
                            id
                            lat
                            lon
                            rekognition
                            testresult                         
                            createdAt
                            updatedAt
                            }
                        }
                    }`
				)
			).then((res) => {
				store.dispatch({
					type: "map/setScans",
					payload: res.data.listScans.items,
				});
			});
			break;
	}

	return next(action);
};
