/** @format */

import React, { useEffect, useState } from "react";
import {
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar,
	IonList,
	IonItem,
	IonLabel,
	IonButton,
	IonBackButton,
	IonButtons,
	IonItemDivider,
	IonInput,
} from "@ionic/react";
import { API, graphqlOperation } from "aws-amplify";
import { add } from "ionicons/icons";
import "./Apps.css";
import { Link, RouteComponentProps } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { appsReducer, initApps, setToggleApp } from "../redux/appsSlice";
import { sign } from "crypto";
import { v4 as uuidv4 } from "uuid";
import gql from "graphql-tag";
import { createScan } from "../graphql/mutations";
import { ResponsiveNetwork, ResponsiveNetworkCanvas } from "@nivo/network";
import { ResponsiveBar } from "@nivo/bar";
import bardata from "./bardata.json";
import networkdata from "./networkdata.json";
import { ResponsiveBubbleHtml } from "@nivo/circle-packing";
import bubbledata from "./bubbledata.json";
import areabumpdata from "./areabumpdata.json";
import { ResponsiveAreaBump } from "@nivo/bump";
import funneldata from "./funneldata.json";
import { ResponsiveFunnel } from "@nivo/funnel";
import { ResponsiveSankey } from "@nivo/sankey";
import sankeydata from "./sankeydata.json";
interface AppProps
	extends RouteComponentProps<{
		app: string;
	}> {}
const Apps: React.FC<AppProps> = ({ match }) => {
	const [showModal, setShowModal] = useState(false);
	const dispatch = useDispatch();
	const appsSlice = useSelector(appsReducer);
	useEffect(() => {
		dispatch(initApps(true));
	}, [dispatch]);
	const app = appsSlice.appRoot;
	useEffect(() => {
		console.log("props", match);
	}, []);

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot='start'>
						<IonBackButton defaultHref='/apps/'></IonBackButton>
					</IonButtons>

					<IonTitle>
						{match.params.app
							.replace(/([A-Z]+)/g, " $1")
							.replace(/([A-Z][a-z])/g, " $1")}
					</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent fullscreen>
				{match.params.app === "QRGenerator"
					? QRGenerator(appsSlice.appRoot.root[match.params.app])
					: null}
				{match.params.app === "ScansPorRegion"
					? GraphBar(appsSlice.appRoot.root[match.params.app])
					: null}
				{/* {match.params.app === "Network"
					? Network(appsSlice.appRoot.root[match.params.app])
					: null} */}
				{match.params.app === "Bubble"
					? Bubble(appsSlice.appRoot.root[match.params.app])
					: null}
				{match.params.app === "AreaBump"
					? AreaBump(appsSlice.appRoot.root[match.params.app])
					: null}
				{match.params.app === "Sankey"
					? Sankey(appsSlice.appRoot.root[match.params.app])
					: null}
				{match.params.app === "mockdata"
					? Mockdata(appsSlice.appRoot.root[match.params.app])
					: null}
			</IonContent>
		</IonPage>
	);
};

export default Apps;
const Mockdata = (slice: any) => {
	const [theArray, setArray] = useState([{}]);
	const setMockData = async () => {
		const maxLat = 15.5;
		const minLat = 14;
		const maxLon = -87;
		const minLon = -88;

		for (var i = 0; i < 100; i++) {
			const theStruct = {
				id: uuidv4(),
				lat: Math.random() * (maxLat - minLat) + minLat,
				lon: Math.random() * (maxLon - minLon) + minLon,
				personID: "04432e8c-b3d5-41d9-bba1-13985b083a27",
				testresult: Math.random() <= 0.6 ? "positive" : "negative",
			};
			if (theStruct.lat && theStruct.lon) {
				setArray((theArray) => [...theArray, theStruct]);
			}
		}
	};
	const setInsertData = async () => {
		theArray.map(async (data: any) => {
			if (data.lat && data.lon) {
				const a = await API.graphql(
					graphqlOperation(createScan, { input: data })
				);
				console.log(a);
			}
		});
	};
	return (
		<>
			<IonButton onClick={(e: any) => setMockData()}>Generate</IonButton>
			<IonButton onClick={(e: any) => setInsertData()}>Insert</IonButton>
		</>
	);
};

const GraphBar = (slice: any) => {
	return <>{MyResponsiveBar(bardata)}</>;
};

const Sankey = (slice: any) => {
	return <>{MyResponsiveSankey(sankeydata)}</>;
};

const Bubble = (slice: any) => {
	return <>{MyResponsiveBubbleHtml({ root: bubbledata })}</>;
};

// const Network = (slice: any) => {
// 	return <>{MyResponsiveNetworkCanvas({ data: networkdata })}</>;
// };

const AreaBump = (slice: any) => {
	console.log("areabumpdata", areabumpdata);
	return <>{MyResponsiveAreaBump(areabumpdata)}</>;
};
const QRGenerator = (slice: any) => {
	const [id, setId] = useState(uuidv4());
	const [sign, setSign] = useState<any>({
		establishment: "",
		message: "",
		lat: 0,
		lon: 0,
		id: id,
	});
	const setNewSign = async () => {
		console.log("New Sign", sign);
		const createSignGql = `
			mutation MyMutation(
				$establishment: String = ""
				$id: ID = ""
				$lat: Float = 1.5
				$lon: Float = 1.5
			) {
				createSign(
					input: {
						establishment: $establishment
						id: $id
						lat: $lat
						lon: $lon
					}
				) {
					id
				}
			}
		`;
		console.log(sign);
		const a = API.graphql(graphqlOperation(createSignGql, sign));
	};
	return (
		<IonList>
			<IonItem>
				<IonLabel position='stacked'>Nombre del Establecimiento</IonLabel>
				<IonInput
					value={sign.establishment}
					onIonChange={(e: any) =>
						setSign({ ...sign, establishment: e.detail.value })
					}></IonInput>
			</IonItem>
			<IonItem>
				<IonLabel position='stacked'>Mensaje</IonLabel>
				<IonInput
					value={sign.message}
					onIonChange={(e: any) =>
						setSign({ ...sign, message: e.detail.value })
					}></IonInput>
			</IonItem>
			<IonItemDivider>Coordenadas</IonItemDivider>
			<IonItem>
				<IonLabel position='stacked'>Latitud</IonLabel>
				<IonInput
					value={sign.lat}
					onIonChange={(e: any) =>
						setSign({ ...sign, lat: e.detail.value })
					}></IonInput>
			</IonItem>
			<IonItem>
				<IonLabel position='stacked'>Longitud</IonLabel>
				<IonInput
					value={sign.lon}
					onIonChange={(e: any) =>
						setSign({ ...sign, lon: e.detail.value })
					}></IonInput>
			</IonItem>
			<IonButton
				href={"http://localhost:3000/qr/" + id}
				target='_blank'
				expand='block'
				fill='outline'
				onClick={(e: any) => setNewSign()}>
				Generar PDF
			</IonButton>
		</IonList>
	);
};

const MyResponsiveBar = (data: any) => (
	<ResponsiveBar
		data={data}
		keys={[
			"infectado",
			"contagioso",
			"expuesto",
			"potencial",
			"inmune",
			"nuevo",
		]}
		indexBy='country'
		margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
		padding={0.3}
		valueScale={{ type: "linear" }}
		colors={{ scheme: "nivo" }}
		defs={[
			{
				id: "dots",
				type: "patternDots",
				background: "inherit",
				color: "#38bcb2",
				size: 4,
				padding: 1,
				stagger: true,
			},
			{
				id: "lines",
				type: "patternLines",
				background: "inherit",
				color: "#eed312",
				rotation: -45,
				lineWidth: 6,
				spacing: 10,
			},
		]}
		fill={[
			{
				match: {
					id: "fries",
				},
				id: "dots",
			},
			{
				match: {
					id: "sandwich",
				},
				id: "lines",
			},
		]}
		borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
		axisTop={null}
		axisRight={null}
		axisBottom={{
			tickSize: 5,
			tickPadding: 5,
			tickRotation: 0,
			legend: "Region",
			legendPosition: "middle",
			legendOffset: 32,
		}}
		axisLeft={{
			tickSize: 5,
			tickPadding: 5,
			tickRotation: 0,
			legend: "Scans",
			legendPosition: "middle",
			legendOffset: -40,
		}}
		labelSkipWidth={12}
		labelSkipHeight={12}
		labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
		legends={[
			{
				dataFrom: "keys",
				anchor: "bottom-right",
				direction: "column",
				justify: false,
				translateX: 120,
				translateY: 0,
				itemsSpacing: 2,
				itemWidth: 100,
				itemHeight: 20,
				itemDirection: "left-to-right",
				itemOpacity: 0.85,
				symbolSize: 20,
				effects: [
					{
						on: "hover",
						style: {
							itemOpacity: 1,
						},
					},
				],
			},
		]}
		animate={true}
		motionStiffness={90}
		motionDamping={15}
	/>
);

// const MyResponsiveNetworkCanvas = (data: any) => (
// 	<ResponsiveNetworkCanvas
// 		data={data}
// 		margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
// 		repulsivity={4}
// 		iterations={60}
// 		nodeColor={function (e) {
// 			return e.color;
// 		}}
// 		nodeBorderWidth={1}
// 		nodeBorderColor={{ theme: "background" }}
// 	/>
// );
const MyResponsiveBubbleHtml = ({ root }: any) => (
	<ResponsiveBubbleHtml
		root={root}
		margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
		identity='name'
		value='loc'
		colors={{ scheme: "paired" }}
		labelSkipRadius={10}
		labelTextColor={{ from: "color", modifiers: [["darker", 0.8]] }}
		borderColor={{ from: "color", modifiers: [["darker", 0.3]] }}
		animate={true}
		motionStiffness={90}
		motionDamping={12}
	/>
);

const MyResponsiveAreaBump = (data: any) => (
	<ResponsiveAreaBump
		data={data}
		margin={{ top: 40, right: 100, bottom: 40, left: 100 }}
		spacing={8}
		colors={{ scheme: "nivo" }}
		blendMode='multiply'
		defs={[
			{
				id: "dots",
				type: "patternDots",
				background: "inherit",
				color: "#38bcb2",
				size: 4,
				padding: 1,
				stagger: true,
			},
			{
				id: "lines",
				type: "patternLines",
				background: "inherit",
				color: "#eed312",
				rotation: -45,
				lineWidth: 6,
				spacing: 10,
			},
		]}
		fill={[
			{
				match: {
					id: "CoffeeScript",
				},
				id: "dots",
			},
			{
				match: {
					id: "TypeScript",
				},
				id: "lines",
			},
		]}
		startLabel='id'
		axisTop={{
			tickSize: 5,
			tickPadding: 5,
			tickRotation: 0,
			legend: "",
			legendPosition: "middle",
			legendOffset: -36,
		}}
		axisBottom={{
			tickSize: 5,
			tickPadding: 5,
			tickRotation: 0,
			legend: "",
			legendPosition: "middle",
			legendOffset: 32,
		}}
	/>
);

const MyResponsiveSankey = (data: any) => (
	<ResponsiveSankey
		data={data}
		margin={{ top: 40, right: 160, bottom: 40, left: 50 }}
		align='justify'
		colors={{ scheme: "category10" }}
		nodeOpacity={1}
		nodeThickness={18}
		nodeInnerPadding={3}
		nodeSpacing={24}
		nodeBorderWidth={0}
		nodeBorderColor={{ from: "color", modifiers: [["darker", 0.8]] }}
		linkOpacity={0.5}
		linkHoverOthersOpacity={0.1}
		enableLinkGradient={true}
		labelPosition='outside'
		labelOrientation='vertical'
		labelPadding={16}
		labelTextColor={{ from: "color", modifiers: [["darker", 1]] }}
		legends={[
			{
				anchor: "bottom-right",
				direction: "column",
				translateX: 130,
				itemWidth: 100,
				itemHeight: 14,
				itemDirection: "right-to-left",
				itemsSpacing: 2,
				itemTextColor: "#999",
				symbolSize: 14,
				effects: [
					{
						on: "hover",
						style: {
							itemTextColor: "#000",
						},
					},
				],
			},
		]}
	/>
);
