/** @format */

import React, { useState, useRef } from "react";
import {
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar,
	IonItem,
	IonList,
	IonListHeader,
	IonLabel,
	IonIcon,
	IonSelect,
	IonSelectOption,
} from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";
import { scanReducer, initScan } from "../redux/scanSlice";
import {
	share,
	camera,
	fastFood,
	language,
	phonePortrait,
	call,
	people,
	pin,
	key,
	keyOutline,
	construct,
} from "ionicons/icons";
import "../components/ExploreContainer";
import { AmplifySignOut } from "@aws-amplify/ui-react";
const Scan: React.FC = () => {
	const dispatch = useDispatch();
	const userSlice = useSelector(scanReducer);

	return (
		<IonPage style={{ height: "100%" }}>
			<IonHeader>
				<IonToolbar>
					<IonTitle>Privascan</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent className='ion-padding' fullscreen>
				<AmplifySignOut button-text='Custom Text'></AmplifySignOut>
				<IonList>
					<IonListHeader>Apps</IonListHeader>
					<IonItem>
						<IonIcon icon={fastFood} slot='start'></IonIcon>
						<IonLabel>Nutrition Guide</IonLabel>
					</IonItem>
					<IonListHeader>Training</IonListHeader>
					<IonItem>
						<IonIcon icon={fastFood} slot='start'></IonIcon>
						<IonLabel>Healthy Nutrition Throughout Life</IonLabel>
					</IonItem>
					<IonItem>
						<IonIcon icon={fastFood} slot='start'></IonIcon>
						<IonLabel>Nutrition and Health</IonLabel>
					</IonItem>
					<IonItem>
						<IonIcon icon={fastFood} slot='start'></IonIcon>
						<IonLabel>Strengthening the Immune System</IonLabel>
					</IonItem>
					<IonItem>
						<IonIcon icon={fastFood} slot='start'></IonIcon>
						<IonLabel>Nutrition for Educators</IonLabel>
					</IonItem>
					<IonItem>
						<IonIcon icon={fastFood} slot='start'></IonIcon>
						<IonLabel>Nutrition for Health Professionals</IonLabel>
					</IonItem>
					<IonItem>
						<IonIcon icon={fastFood} slot='start'></IonIcon>
						<IonLabel>Food Safety Basics</IonLabel>
					</IonItem>
					<IonItem>
						<IonIcon icon={fastFood} slot='start'></IonIcon>
						<IonLabel>Food Maintenance</IonLabel>
					</IonItem>

					<IonListHeader>Configuration</IonListHeader>
					<IonItem>
						<IonIcon icon={language} slot='start'></IonIcon>
						<IonLabel>Language</IonLabel>
						<IonSelect
							value='en'
							placeholder='Select One'
							interface='action-sheet'>
							<IonSelectOption value='en'>English</IonSelectOption>
							<IonSelectOption value='es'>Espanol</IonSelectOption>
						</IonSelect>
					</IonItem>
					<IonItem>
						<IonIcon icon={camera} slot='start'></IonIcon>
						<IonLabel>Default Test Type</IonLabel>
						<IonSelect
							value='RT-PCR'
							placeholder='Select One'
							interface='action-sheet'>
							<IonSelectOption value='RT-PCR'>RT-PCR</IonSelectOption>
							<IonSelectOption value='Temp'>Temp</IonSelectOption>
						</IonSelect>
					</IonItem>

					<IonItem>
						<IonIcon icon={call} slot='start'></IonIcon>
						<IonLabel>Default Country Code</IonLabel>
						<IonSelect
							value='504'
							placeholder='Select One'
							interface='action-sheet'>
							<IonSelectOption value='504'>(504) Honduras</IonSelectOption>
							<IonSelectOption value='1'>(1) United States</IonSelectOption>
							<IonSelectOption value='44'>(44) United Kingdom</IonSelectOption>
						</IonSelect>
					</IonItem>
					<IonItem>
						<IonIcon icon={pin} slot='start'></IonIcon>
						<IonLabel>Map Areas</IonLabel>
					</IonItem>
					<IonListHeader>Administration</IonListHeader>
					<IonItem>
						<IonIcon icon={people} slot='start'></IonIcon>
						<IonLabel>Users</IonLabel>
					</IonItem>
					<IonItem>
						<IonIcon icon={construct} slot='start'></IonIcon>
						<IonLabel>Roles</IonLabel>
					</IonItem>
					<IonItem>
						<IonIcon icon={key} slot='start'></IonIcon>
						<IonLabel>Login Audit</IonLabel>
					</IonItem>
				</IonList>
			</IonContent>
		</IonPage>
	);
};

export default Scan;
