/** @format */

import React from "react";
import {
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar,
	IonList,
	IonItem,
	IonLabel,
	IonIcon,
	IonBackButton,
	IonButtons,
	IonListHeader,
	IonInput,
} from "@ionic/react";
import { Document, Page } from "react-pdf";
import { scan } from "ionicons/icons";

const MedHistory: React.FC = () => {
	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot='start'>
						<IonBackButton defaultHref='/apps'></IonBackButton>
					</IonButtons>
					<IonTitle>Expediente</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent fullscreen>
				<IonHeader collapse='condense'>
					<IonToolbar>
						<IonTitle size='large'>Expediente</IonTitle>
					</IonToolbar>
				</IonHeader>
				<IonList>
					<IonListHeader>Informacion del Paciente</IonListHeader>
					<IonItem>
						<IonLabel position='floating'>Nombre</IonLabel>
						<IonInput value='ISAAC GUERRA LOREDO'></IonInput>
					</IonItem>
					<IonItem>
						<IonLabel position='floating'>Fecha de Nacimineto</IonLabel>
						<IonInput></IonInput>
					</IonItem>
					<IonItem>
						<IonLabel position='floating'>Dirección</IonLabel>
						<IonInput></IonInput>
					</IonItem>
					<IonItem>
						<IonLabel position='floating'>Teléfono</IonLabel>
						<IonInput></IonInput>
					</IonItem>
					<IonItem>
						<IonLabel position='floating'>Ciudad</IonLabel>
						<IonInput></IonInput>
					</IonItem>
					<IonItem>
						<IonLabel position='floating'>Estado</IonLabel>
						<IonInput></IonInput>
					</IonItem>
				</IonList>
			</IonContent>
		</IonPage>
	);
};

export default MedHistory;
