/** @format */

import React, { useState, useRef, useEffect } from "react";
import {
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar,
	useIonViewWillEnter,
	IonFab,
	IonFabButton,
	IonIcon,
	IonSegment,
	IonSegmentButton,
	IonLabel,
	IonGrid,
	IonRow,
	IonCol,
	IonButton,
	IonList,
	IonItem,
	IonListHeader,
	IonCardContent,
	IonCardHeader,
	IonCardTitle,
	IonCard,
	IonDatetime,
	IonCardSubtitle,
	IonToggle,
	IonText,
	IonInput,
	IonItemGroup,
	IonLoading,
	IonToast,
	IonPopover,
	IonProgressBar,
	IonBackButton,
	IonButtons,
} from "@ionic/react";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import { scanReducer, initScan, setGeolocation } from "../redux/scanSlice";
import { userReducer } from "../redux/userSlice";
import {
	share,
	camera,
	cellular,
	cellularOutline,
	call,
	phonePortrait,
	medical,
	pulse,
	calendar,
	person,
	remove,
	close,
} from "ionicons/icons";
import Amplify, { Storage } from "aws-amplify";
import awsconfig from "../aws-exports";
import useGeolocation from "react-hook-geolocation";
Amplify.configure(awsconfig);
const Scan: React.FC = () => {
	const dispatch = useDispatch();
	const userSlice = useSelector(userReducer);
	const scanSlice = useSelector(scanReducer);
	const uploadRef = React.useRef<HTMLInputElement>(null);
	const geolocation = useGeolocation(
		{
			enableHighAccuracy: false,
			maximumAge: 15000,
			timeout: 12000,
		},
		(geo: any) => {
			if (geo.latitude) {
				dispatch(setGeolocation(geo));
			}
		}
	);

	const [image, setImage] = useState({ preview: "", raw: "" });
	const [location, setLocation] = useState<any | undefined | null>({});

	const [showLoading, setShowLoading] = useState<boolean>(false);
	const [showToast, setShowToast] = useState<boolean>(false);
	const [testType, setTestType] = useState<string | undefined>();
	const [testResult, setTestResult] = useState<string | undefined>();
	const [testDate, setTestDate] = useState<string | undefined>();
	const [newContactCountry, setNewContactCountry] = useState<
		string | undefined
	>("504");
	const [newContactNumber, setNewContactNumber] = useState<string | undefined>(
		""
	);
	const [contactNumbers, setContactNumber] = useState<string | undefined>();
	const [showPopover, setShowPopover] = useState<{
		open: boolean;
		event: Event | undefined;
	}>({
		open: false,
		event: undefined,
	});
	const [uploadPercent, setUploadPercent] = useState<number>(0);
	const today = new Date();
	const dateArray = new Array();
	for (var i = 1; i < 7; i++) {
		let theNewDate = new Date(today);
		theNewDate.setDate(theNewDate.getDate() - i);

		dateArray.push({
			show: theNewDate.getMonth() + "/" + theNewDate.getDate(),
			date: theNewDate.toString(),
		});
	}
	useEffect(() => {
		dispatch(initScan({ init: true }));
	}, [userSlice.auth]);
	useEffect(() => {
		if (showLoading === false) {
			setTestDate("");
			setTestResult("");
			setTestType("");
		}
	}, [showLoading]);

	const setPicture = async (e: any) => {
		setShowLoading(true);
		const scanid = uuidv4();
		const metadata = {
			scanid: scanid,
			personid: uuidv4(),
			eventepoch: Date.now().toString(),
			testtype: testType,
			testdate: testDate,
			testresult: testResult,
			lat: scanSlice.geolocation.latitude.toString(),
			lon: scanSlice.geolocation.longitude.toString(),
			contact: contactNumbers ? contactNumbers : "",
			userID: userSlice.auth.attributes.sub,
		};
		// console.log(metadata);
		await Storage.put(scanid + ".jpg", e[0], {
			progressCallback: (progress: any) => {
				setUploadPercent(progress.loaded / progress.total);
			},
			contentType: e[0].type,
			metadata: metadata,
		}).then((res) => {
			setShowLoading(false);
			setShowToast(true);
			setUploadPercent(0);
			setContactNumber("");
		});
	};
	const setSegment = (ev: string | undefined) => {
		setTestType(ev);
	};
	const setResult = (ev: string | undefined) => {
		setTestResult(ev);
	};
	const setDate = (ev: string | undefined) => {
		setTestDate(ev);
	};

	return (
		<IonPage>
			<IonPopover
				isOpen={showPopover.open}
				event={showPopover.event}
				onDidDismiss={(e) => setShowPopover({ open: false, event: undefined })}>
				<IonListHeader>Country</IonListHeader>
				<IonList>
					<IonButton
						expand='block'
						fill='outline'
						onClick={(e) => setNewContactCountry("1")}>
						+1 (United States)
					</IonButton>
					<IonButton
						expand='block'
						fill='outline'
						onClick={(e) => setNewContactCountry("504")}>
						+504 (Honduras)
					</IonButton>
					<IonButton
						expand='block'
						fill='outline'
						onClick={(e) => setNewContactCountry("44")}>
						+44 (United Kingdom)
					</IonButton>
				</IonList>
			</IonPopover>
			<IonLoading
				isOpen={showLoading}
				onDidDismiss={() => setShowLoading(false)}
				message={"Uploading File. Please Wait"}
			/>
			<IonToast
				isOpen={showToast}
				onDidDismiss={() => setShowToast(false)}
				message='Scan uploaded.'
				duration={3000}
			/>
			<IonHeader>
				<IonToolbar>
					<IonTitle>Scan</IonTitle>
					<IonButtons slot='start'>
						<IonBackButton defaultHref='/apps'></IonBackButton>
					</IonButtons>
				</IonToolbar>
				<IonProgressBar
					hidden={uploadPercent === 0}
					color='primary'
					value={uploadPercent}
					type='determinate'></IonProgressBar>
			</IonHeader>

			<IonContent fullscreen>
				{
					<>
						{scanSlice.geolocation.latitude ? (
							<>
								<IonCard>
									<IonCardHeader>
										<IonCardTitle>
											<IonIcon icon={medical}></IonIcon>&nbsp;Type
										</IonCardTitle>
										<IonCardSubtitle>
											The type of test that was administered
										</IonCardSubtitle>
									</IonCardHeader>
									<IonCardContent>
										<IonGrid>
											<IonRow>
												<IonCol>
													<IonButton
														expand='block'
														fill={
															testType === "Temperature" ? "solid" : "outline"
														}
														onClick={(e) => setSegment("Temperature")}>
														Temp
													</IonButton>
												</IonCol>
												<IonCol>
													<IonButton
														expand='block'
														fill={testType === "Binax" ? "solid" : "outline"}
														onClick={(e) => setSegment("Binax")}>
														Binax
													</IonButton>
												</IonCol>
												<IonCol>
													<IonButton
														expand='block'
														fill={testType === "Plasma" ? "solid" : "outline"}
														onClick={(e) => setSegment("Plasma")}>
														Plasma
													</IonButton>
												</IonCol>
											</IonRow>
											<IonRow>
												<IonCol>
													<IonButton
														expand='block'
														fill={testType === "Antigen" ? "solid" : "outline"}
														onClick={(e) => setSegment("Antigen")}>
														Antigen
													</IonButton>
												</IonCol>
												<IonCol>
													<IonButton
														expand='block'
														fill={testType === "Antibody" ? "solid" : "outline"}
														onClick={(e) => setSegment("Antibody")}>
														Antibody
													</IonButton>
												</IonCol>
												<IonCol>
													<IonButton
														expand='block'
														fill={testType === "PCR" ? "solid" : "outline"}
														onClick={(e) => setSegment("PCR")}>
														PCR
													</IonButton>
												</IonCol>
											</IonRow>
										</IonGrid>
									</IonCardContent>
								</IonCard>
								<IonCard hidden={testType ? false : true}>
									<IonCardHeader>
										<IonCardTitle>
											<IonIcon icon={pulse}></IonIcon>&nbsp;Result
										</IonCardTitle>
										<IonCardSubtitle>
											Positive: traces of virus were found
										</IonCardSubtitle>
									</IonCardHeader>
									<IonCardContent>
										<IonGrid>
											<IonRow>
												<IonCol>
													<IonButton
														expand='block'
														size='default'
														fill={
															testResult === "negative" ? "solid" : "outline"
														}
														onClick={(e) => setResult("negative")}>
														Negative
													</IonButton>
												</IonCol>
												<IonCol>
													<IonButton
														expand='block'
														size='default'
														fill={
															testResult === "positive" ? "solid" : "outline"
														}
														onClick={(e) => setResult("positive")}>
														Positive
													</IonButton>
												</IonCol>
											</IonRow>
											<IonRow>
												<IonCol>
													{testResult === "positive" ? (
														<IonList>
															<IonListHeader>Contact Tracing</IonListHeader>
															{contactNumbers ? (
																<IonItem>
																	<IonLabel>{contactNumbers}</IonLabel>
																	<IonButton
																		slot='end'
																		onClick={(e) => {
																			setContactNumber(undefined);
																			setNewContactNumber(undefined);
																		}}>
																		<IonIcon icon={close}></IonIcon>
																	</IonButton>
																</IonItem>
															) : (
																<>
																	<IonItem>
																		<IonButton
																			slot='start'
																			onClick={(e) =>
																				setShowPopover({
																					open: true,
																					event: e.nativeEvent,
																				})
																			}>
																			{newContactCountry}
																		</IonButton>

																		<IonInput
																			value={newContactNumber}
																			placeholder='Cell phone number'
																			type='number'
																			onIonChange={(e: any) =>
																				setNewContactNumber(e.target.value)
																			}></IonInput>
																	</IonItem>
																	<IonButton
																		hidden={!newContactNumber}
																		expand='block'
																		fill='clear'
																		onClick={(e) =>
																			setContactNumber(
																				String(newContactCountry) +
																					String(newContactNumber)
																			)
																		}>
																		Add Number
																	</IonButton>
																</>
															)}
														</IonList>
													) : null}
												</IonCol>
											</IonRow>
										</IonGrid>
									</IonCardContent>
								</IonCard>
								<IonCard hidden={testResult ? false : true}>
									<IonCardHeader>
										<IonCardTitle>
											<IonIcon icon={calendar}></IonIcon>&nbsp;Date
										</IonCardTitle>
										<IonCardSubtitle>
											The date the test was performed
										</IonCardSubtitle>
									</IonCardHeader>
									<IonCardContent>
										<IonGrid>
											<IonRow>
												{dateArray.map((theDate, i) => (
													<IonCol key={i} size='4'>
														<IonButton
															fill={
																testDate === theDate.show ? "solid" : "outline"
															}
															size='default'
															expand='block'
															onClick={(e) => setDate(theDate.show)}>
															{theDate.show}
														</IonButton>
													</IonCol>
												))}
											</IonRow>
										</IonGrid>
									</IonCardContent>
								</IonCard>

								<IonCard
									hidden={
										testDate && scanSlice.geolocation.latitude ? false : true
									}>
									<IonCardHeader>
										<IonCardTitle>
											<IonIcon icon={person}></IonIcon>&nbsp;Photo
										</IonCardTitle>
										<IonCardSubtitle>
											Aim the camera at the subject's face and take a photo
										</IonCardSubtitle>
									</IonCardHeader>
									<IonCardContent>
										<IonButton
											fill='outline'
											expand='block'
											onClick={(e) => uploadRef.current?.click()}>
											<IonIcon icon={camera} />
											<IonLabel></IonLabel>
										</IonButton>
									</IonCardContent>
								</IonCard>
							</>
						) : (
							<IonCard className='ion-padding-bottom ion-margin-bottom'>
								<IonCardHeader>
									<IonCardTitle>Location</IonCardTitle>
									<IonCardSubtitle>
										Location services must be enabled on device. Please reload
										the application and try again. {geolocation.latitude}
									</IonCardSubtitle>
								</IonCardHeader>
							</IonCard>
						)}
					</>
				}
				<IonCard>
					<IonCardContent>
						<input
							capture='user'
							accept='image/*'
							type='file'
							ref={uploadRef}
							style={{ opacity: 0 }}
							onChange={(e) => setPicture(e.target.files)}></input>
					</IonCardContent>
				</IonCard>
			</IonContent>
		</IonPage>
	);
};

export default Scan;
