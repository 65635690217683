/** @format */

import React from "react";
import {
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar,
	IonList,
	IonItem,
	IonLabel,
	IonIcon,
	IonBackButton,
	IonButtons,
	IonListHeader,
	IonNote,
	IonBadge,
	IonAvatar,
	IonText,
} from "@ionic/react";
import { scan } from "ionicons/icons";

const Vaccine: React.FC = () => {
	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot='start'>
						<IonBackButton defaultHref='/apps'></IonBackButton>
					</IonButtons>
					<IonTitle>Vacuna</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent fullscreen>
				<IonHeader collapse='condense'>
					<IonToolbar>
						<IonTitle size='large'>Vacuna</IonTitle>
					</IonToolbar>
				</IonHeader>
				<div className='ion-padding'>
					<IonText className='ion-padding'>
						<h1>Proxima Vacuna: 12/9/2021</h1>
					</IonText>
				</div>

				<IonListHeader>Historial</IonListHeader>
				<IonList>
					<IonItem>
						<IonAvatar slot='start'>
							<img src='assets/appicons/astrazeneca.png' alt='az'></img>
						</IonAvatar>
						<IonLabel>
							<h3>AstraZeneca</h3>
							<p>AZD1222</p>
						</IonLabel>
						<IonNote>
							<IonBadge>3/17/2021</IonBadge>
						</IonNote>
					</IonItem>
					<IonItem>
						<IonAvatar slot='start'>
							<img src='assets/appicons/astrazeneca.png' alt='az'></img>
						</IonAvatar>
						<IonLabel>
							<h3>AstraZeneca</h3>
							<p>AZD1222</p>
						</IonLabel>
						<IonNote>
							<IonBadge>6/2/2021</IonBadge>
						</IonNote>
					</IonItem>
					<IonItem>
						<IonAvatar slot='start'>
							<img src='assets/appicons/astrazeneca.png' alt='az'></img>
						</IonAvatar>
						<IonLabel>
							<h3>AstraZeneca</h3>
							<p>AZD1222</p>
						</IonLabel>
						<IonNote>
							<IonBadge>9/1/2021</IonBadge>
						</IonNote>
					</IonItem>
				</IonList>
			</IonContent>
		</IonPage>
	);
};

export default Vaccine;
