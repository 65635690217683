/** @format */

import React from "react";
import {
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar,
	IonList,
	IonItem,
	IonLabel,
	IonIcon,
	IonCard,
	IonCardContent,
	IonGrid,
	IonRow,
	IonCardHeader,
	IonCardTitle,
	IonCardSubtitle,
	IonButtons,
	IonBackButton,
} from "@ionic/react";
import ExploreContainer from "../components/ExploreContainer";
import { fastFood, scan } from "ionicons/icons";

const Training: React.FC = () => {
	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons>
						<IonBackButton defaultHref='/apps'></IonBackButton>
					</IonButtons>
					<IonTitle>Educación</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent fullscreen>
				<IonHeader collapse='condense'>
					<IonToolbar>
						<IonTitle size='large'>Educación</IonTitle>
					</IonToolbar>
				</IonHeader>
				<IonTitle>Nutrición</IonTitle>
				<IonGrid>
					<IonRow>
						<IonCard>
							<img src='assets/apps/health_01.jpg'></img>
							<IonCardContent class='ion-no-padding'>
								<IonList>
									<IonItem routerLink='training/course'>
										<IonIcon icon={fastFood} slot='start'></IonIcon>
										<IonLabel>
											Porque es importante tener una buena alimentacion?
										</IonLabel>
									</IonItem>
								</IonList>
							</IonCardContent>
						</IonCard>
					</IonRow>
					<IonRow>
						<IonCard>
							<img src='assets/apps/nutrition_03.jpg'></img>
							<IonCardContent>
								<IonList>
									<IonItem routerLink='training/course'>
										<IonIcon icon={fastFood} slot='start'></IonIcon>
										<IonLabel>
											Porque es importante tener una buena alimentacion?
										</IonLabel>
									</IonItem>
								</IonList>
							</IonCardContent>
						</IonCard>
					</IonRow>
					<IonRow>
						<IonCard>
							<img src='assets/apps/finanzas_02.jpg'></img>
							<IonCardContent>
								<IonList>
									<IonItem routerLink='training/course'>
										<IonIcon icon={fastFood} slot='start'></IonIcon>
										<IonLabel>
											Porque es importante tener una buena alimentacion?
										</IonLabel>
									</IonItem>
								</IonList>
							</IonCardContent>
						</IonCard>
					</IonRow>
				</IonGrid>
			</IonContent>
		</IonPage>
	);
};

export default Training;
