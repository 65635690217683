/** @format */

import React, { useEffect, useState } from "react";
import {
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar,
	IonList,
	IonItem,
	IonLabel,
	IonIcon,
	IonCol,
	IonGrid,
	IonRow,
	IonFab,
	IonFabButton,
	IonButton,
	IonModal,
	IonListHeader,
	IonAvatar,
	IonBadge,
	IonCard,
	IonCardHeader,
	IonCardTitle,
	IonBackButton,
	IonButtons,
	IonCardSubtitle,
	IonNote,
	IonItemGroup,
	IonItemDivider,
	IonCardContent,
	IonText,
	IonChip,
	IonSegment,
	IonSegmentButton,
	IonTextarea,
	IonInput,
	IonCheckbox,
	IonRadioGroup,
	IonRadio,
} from "@ionic/react";
import { add } from "ionicons/icons";
import "./Apps.css";
import { Link, RouteComponentProps } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { appsReducer, initApps, setToggleApp } from "../redux/appsSlice";
interface AppProps
	extends RouteComponentProps<{
		app: string;
	}> {}
const Apps: React.FC<AppProps> = ({ match }) => {
	const [showModal, setShowModal] = useState(false);
	const dispatch = useDispatch();
	const appsSlice = useSelector(appsReducer);
	useEffect(() => {
		dispatch(initApps(true));
	}, [dispatch]);
	const app = appsSlice.appRoot;
	useEffect(() => {
		console.log("props", match);
	}, []);
	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot='start'>
						<IonBackButton defaultHref='/apps/'></IonBackButton>
					</IonButtons>

					<IonTitle>{match.params.app}</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent fullscreen>
				{match.params.app === "Oportunidades"
					? Oportunidades(appsSlice.appRoot.root[match.params.app])
					: null}
				{match.params.app === "Comercio"
					? Comercio(appsSlice.appRoot.root[match.params.app])
					: null}
				{match.params.app === "Hospital"
					? Hospital(appsSlice.appRoot.root[match.params.app])
					: null}
				{match.params.app === "Hogar"
					? Hogar(appsSlice.appRoot.root[match.params.app])
					: null}
				{match.params.app === "Educación"
					? Educacion(appsSlice.appRoot.root[match.params.app])
					: null}
				{match.params.app === "Empresa"
					? Empresa(appsSlice.appRoot.root[match.params.app])
					: null}
			</IonContent>
		</IonPage>
	);
};

export default Apps;
const Empresa = (slice: any) => {
	return (
		<>
			{Object.keys(slice).map((key, val) => (
				<IonCard key={key}>
					<IonCardHeader>
						<IonCardTitle>{key}</IonCardTitle>
						<IonCardSubtitle></IonCardSubtitle>
					</IonCardHeader>

					<IonGrid>
						<IonRow>
							{slice[key].map((herramienta: any, index: any) => (
								<IonCol
									key={herramienta.Nombre}
									size={
										herramienta.Position.Priority === "Banner" ? "12" : "6"
									}>
									<img
										src={"/assets/appicons/" + herramienta.Icon}
										width='100%'></img>
									{herramienta.Nombre}
								</IonCol>
							))}
						</IonRow>
					</IonGrid>
				</IonCard>
			))}
		</>
	);
};
const Educacion = (slice: any) => {
	return (
		<>
			{Object.keys(slice).map((key, val) => (
				<IonCard key={key}>
					<>
						<IonCardHeader>
							<IonCardTitle>{key}</IonCardTitle>
							<IonCardSubtitle>
								Cursos vocacionales avalados por INFOP, IHCI y UNAH
							</IonCardSubtitle>
						</IonCardHeader>
						<IonGrid>
							<IonRow>
								{slice[key].map((curso: any, index: any) => (
									<IonCol
										key={curso.Nombre}
										size={curso.Position.Priority === "Banner" ? "12" : "6"}>
										<img
											src={"/assets/appicons/" + curso.Icon}
											width='100%'></img>
										{/* <IonText>{curso.Nombre}</IonText> */}
									</IonCol>
								))}
							</IonRow>
						</IonGrid>
					</>
				</IonCard>
			))}
		</>
	);
};

const Hogar = (slice: any) => {
	return (
		<div>
			{Object.keys(slice).map((key, val) => (
				<IonCard key={key}>
					{key === "Recetas" ? (
						<>
							<IonCardHeader>
								<IonCardTitle>{key}</IonCardTitle>
								<IonCardSubtitle>
									Estas recetas han sido seleccionadas para ti en base a los
									ingredientes en tu despensa, ahorro de economia, tu dieta
									recomendada por tu doctor y la disponibilidad local de los
									ingredientes
								</IonCardSubtitle>
							</IonCardHeader>
							<IonGrid>
								<IonRow>
									{slice[key].Banner.map((receta: any, index: any) => (
										<IonCol size='12' key={index}>
											<img
												src={"/assets/appicons/" + receta.Icon}
												width='100%'
											/>
											<IonText>{receta.Nombre}</IonText>
										</IonCol>
									))}
									{slice[key].Destacadas.map((receta: any, val: any) => (
										<IonCol size='6' key={val}>
											<img src={"/assets/appicons/" + receta.Icon} />
											<IonText>{receta.Nombre}</IonText>
										</IonCol>
									))}
								</IonRow>
							</IonGrid>
						</>
					) : null}
					{key === "Despensa" ? (
						<>
							<IonCardHeader>
								<IonCardTitle>{key}</IonCardTitle>
							</IonCardHeader>
							<IonCardContent>
								<IonList>
									{slice[key].Ingredientes.map((ingrediente: any) => (
										<IonItem key={ingrediente.Nombre}>
											<IonLabel>
												<h3>{ingrediente.Nombre}</h3>
											</IonLabel>
											<IonBadge slot='end'>{ingrediente.Cantidad}</IonBadge>
										</IonItem>
									))}
								</IonList>
							</IonCardContent>
						</>
					) : null}
				</IonCard>
			))}
		</div>
	);
};

const Oportunidades = (slice: any) => {
	return (
		<>
			{Object.keys(slice).map((key, val) => (
				<IonCard key={key}>
					<IonCardHeader>
						<IonCardTitle>{key}</IonCardTitle>
						<IonCardSubtitle></IonCardSubtitle>
					</IonCardHeader>
					{key === "Empleos" ? (
						<IonCardContent>
							<IonList>
								{slice[key].Plazas.map((plaza: any) => (
									<IonItem key={plaza.Nombre}>
										<IonLabel>
											<h3>{plaza.Nombre}</h3>
											<p>{plaza.Empresa}</p>
										</IonLabel>
									</IonItem>
								))}
							</IonList>
						</IonCardContent>
					) : null}
					{key === "Currículum" ? (
						<IonCardContent>
							<IonList>
								<IonItem>
									<IonLabel position='stacked'>Nombre Completo</IonLabel>
									<IonInput></IonInput>
								</IonItem>
								<IonItem>
									<IonLabel position='stacked'>Numero Identidad</IonLabel>
									<IonInput></IonInput>
								</IonItem>
								<IonItem>
									<IonLabel position='stacked'>Fecha de Nacimiento</IonLabel>
									<IonInput></IonInput>
								</IonItem>
								<IonItem>
									<IonLabel position='stacked'>Dirección</IonLabel>
									<IonTextarea></IonTextarea>
								</IonItem>
								<IonItem>
									<IonLabel position='stacked'>Correo Electrónico</IonLabel>
									<IonTextarea></IonTextarea>
								</IonItem>
								<IonItem>
									<IonLabel position='stacked'>Numero Telefónico</IonLabel>
									<IonInput></IonInput>
								</IonItem>

								<IonRadioGroup>
									<IonItem>
										<IonLabel position='stacked'>Estado Civil</IonLabel>
										<IonItem lines='none'>
											<IonLabel>Soltero(a)</IonLabel>
											<IonRadio slot='start' value='Soltero' />
										</IonItem>
										<IonItem>
											<IonLabel>Casado(a)</IonLabel>
											<IonRadio slot='start' value='Casado' />
										</IonItem>
									</IonItem>
								</IonRadioGroup>

								<IonItem>
									<IonLabel position='stacked'>Educación</IonLabel>
									<IonTextarea></IonTextarea>
								</IonItem>
								<IonItem>
									<IonLabel position='stacked'>Experiencia Laboral</IonLabel>
									<IonTextarea></IonTextarea>
								</IonItem>
								<IonItem>
									<IonLabel position='stacked'>Referencias</IonLabel>
									<IonTextarea></IonTextarea>
								</IonItem>
								<IonButton expand='block' fill='outline'>
									Actualizar
								</IonButton>
							</IonList>
						</IonCardContent>
					) : null}
				</IonCard>
			))}
		</>
	);
};

const Comunidad = (slice: any) => {
	return (
		<>
			{Object.keys(slice).map((key, val) => (
				<IonCard key={key}>
					<IonCardHeader>
						<IonCardTitle>{key}</IonCardTitle>
						<IonCardSubtitle></IonCardSubtitle>
					</IonCardHeader>
				</IonCard>
			))}
		</>
	);
};

const Comercio = (slice: any) => {
	return (
		<div>
			{Object.keys(slice).map((key, val) => (
				<IonCard key={key}>
					<IonCardHeader>
						<IonCardTitle>{key}</IonCardTitle>
					</IonCardHeader>
					{key === "Tiendas" ? (
						<IonGrid>
							<IonRow>
								{slice[key].Banner.map((tienda: any, val: any) => (
									<IonCol size='12' key={val}>
										<img src={"/assets/appicons/" + tienda.Icon} width='100%' />
									</IonCol>
								))}
								{slice[key].Destacadas.map((tienda: any, val: any) => (
									<IonCol size='6' key={val}>
										<img src={"/assets/appicons/" + tienda.Icon} />
									</IonCol>
								))}
							</IonRow>
							<IonList>
								{slice[key].List.map((tienda: any, val: any) => (
									<IonItem key={tienda.Nombre}>
										<IonAvatar slot='start'>
											<img src={"/assets/appicons/" + tienda.Icon}></img>
										</IonAvatar>
										<IonLabel>{tienda.Nombre}</IonLabel>
									</IonItem>
								))}
							</IonList>
						</IonGrid>
					) : null}
				</IonCard>
			))}
		</div>
	);
};
const Hospital = (slice: any) => {
	return (
		<div>
			<IonCard>
				<IonCardHeader>
					<IonCardTitle>Epidemiología</IonCardTitle>
				</IonCardHeader>
				<IonCardContent>
					<IonGrid>
						<IonRow>
							<IonCol>
								<Link to='/apps/map/14.1/-87.2/12'>
									<img src={"/assets/appicons/map.jpg"} width='100%'></img>
								</Link>
								<IonText>Mapas</IonText>
							</IonCol>
						</IonRow>
						<IonRow>
							<IonCol>
								<Link to='/scan'>
									<img src={"/assets/appicons/test.jpg"}></img>
								</Link>
								<IonText>Prueba</IonText>
							</IonCol>
							<IonCol>
								<Link to='/apps/detail/QRGenerator'>
									<img src={"/assets/appicons/qrphone.jpg"}></img>
								</Link>
								<IonText>Generador QR</IonText>
							</IonCol>
						</IonRow>
						<IonRow>
							<IonCol>
								<Link to='/apps/detail/Bubble'>
									<img src={"/assets/appicons/bubble_01.jpg"}></img>
								</Link>
								<IonText>Nodos Familiares</IonText>
							</IonCol>
							<IonCol>
								<Link to='/apps/detail/ScansPorRegion'>
									<img src={"/assets/appicons/bargraph_01.jpg"}></img>
								</Link>
								<IonText>Scans Regionales</IonText>
							</IonCol>
						</IonRow>
						<IonRow>
							<IonCol>
								<Link to='/apps/detail/Sankey'>
									<img src={"/assets/appicons/sankey_01.jpg"}></img>
								</Link>
								<IonText>Sankey/Persona</IonText>
							</IonCol>
							<IonCol>
								<Link to='/apps/detail/AreaBump'>
									<img src={"/assets/appicons/candlestick_01.jpg"}></img>
								</Link>
								<IonText>Predicciones/Edad</IonText>
							</IonCol>
							<IonCol>
								<Link to='/apps/detail/mockdata'>
									<img src={"/assets/appicons/candlestick_01.jpg"}></img>
								</Link>
								<IonText>Generate data</IonText>
							</IonCol>
						</IonRow>
					</IonGrid>
				</IonCardContent>
			</IonCard>
			<IonCard>
				<IonCardHeader>
					<IonCardTitle>Mapas</IonCardTitle>
				</IonCardHeader>
				<IonCardContent>
					<IonGrid>
						<IonRow>
							<IonCol>
								<Link to='/apps/map/15/-87/7'>
									<img
										src={"/assets/appicons/honduras_01.jpg"}
										width='100%'></img>
								</Link>
								<IonText>Honduras</IonText>
							</IonCol>
						</IonRow>
						<IonRow>
							<IonCol>
								<Link to='/apps/map/14.1/-87.2/12'>
									<img src={"/assets/appicons/tegucigalpa_01.jpg"}></img>
								</Link>
								<IonText>Tegucigalpa</IonText>
							</IonCol>
							<IonCol>
								<Link to='/apps/map/15.52/-88/12'>
									<img src={"/assets/appicons/sanpedro_01.jpg"}></img>
								</Link>
								<IonText>San Pedro Sula</IonText>
							</IonCol>
						</IonRow>
						<IonRow>
							<IonCol>
								<Link to='/apps/map/15.78/-86.78/13'>
									<img src={"/assets/appicons/laceiba_01.jpg"}></img>
								</Link>
								<IonText>La Ceiba</IonText>
							</IonCol>
							<IonCol>
								<Link to='/apps/map/14/-86.5/10'>
									<img src={"/assets/appicons/elparaiso_01.jpg"}></img>
								</Link>
								<IonText>El Paraiso</IonText>
							</IonCol>
						</IonRow>
					</IonGrid>
				</IonCardContent>
			</IonCard>
			{Object.keys(slice).map((key, val) => (
				<IonCard key={key}>
					<IonCardHeader>
						<IonCardTitle>{key}</IonCardTitle>
					</IonCardHeader>
					<IonCardContent>
						{key === "Vacuna" ? (
							<IonList>
								{/* <IonListHeader>Historial</IonListHeader> */}
								<IonItemGroup>
									<IonItemDivider>
										<IonLabel>Proximas</IonLabel>
									</IonItemDivider>
									<IonItem>
										<IonAvatar slot='start'>
											<img src={"/assets/appicons/jj.png"}></img>
										</IonAvatar>
										<IonLabel>
											<h3>{slice[key].Proxima.Tipo}</h3>
											<p>{slice[key].Proxima.Suplidor}</p>
										</IonLabel>
										<IonBadge slot='end'>{slice[key].Proxima.Fecha}</IonBadge>
									</IonItem>

									<IonItemDivider>
										<IonLabel>Historial</IonLabel>
									</IonItemDivider>
									{slice[key].Historial.map((item: any, key: any) => (
										<IonItem key={key}>
											<IonAvatar slot='start'>
												<img
													src={"/assets/appicons/" + item.Icon + ".png"}></img>
											</IonAvatar>
											<IonLabel>
												<h3>{item.Tipo}</h3>
												<p>{item.Suplidor}</p>
											</IonLabel>
											<IonBadge slot='end'>{item.Fecha}</IonBadge>
										</IonItem>
									))}
								</IonItemGroup>
							</IonList>
						) : null}
						{key === "Farmacia" ? (
							<IonList>
								<IonItemGroup>
									<IonItemDivider>
										<IonLabel>Recetas</IonLabel>
									</IonItemDivider>

									{slice[key].Recetas.map((item: any, key: any) => (
										<IonItem key={key}>
											<IonLabel>
												<h3>{item.Nombre}</h3>
												<p>
													{item.Aplicacion} {item.Frecuencia}
												</p>
											</IonLabel>
											<IonBadge slot='end'>{item.Unidad}</IonBadge>
										</IonItem>
									))}
								</IonItemGroup>
							</IonList>
						) : null}
						{key === "Consulta" ? (
							<>
								<IonButton expand='block' fill='outline'>
									Solicitar Cita
								</IonButton>
								<IonList>
									{slice[key].Citas.map((item: any, key: any) => (
										<IonItem key={key}>
											<IonLabel>
												<h3>{item.Nombre}</h3>
												<p>{item.Notas}</p>
											</IonLabel>

											<IonBadge slot='end'>
												{item.Fecha}
												<br></br> {item.Hora}
											</IonBadge>
										</IonItem>
									))}
								</IonList>
							</>
						) : null}
					</IonCardContent>
				</IonCard>
			))}
		</div>
	);
};
