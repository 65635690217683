/** @format */

import React, { useState, useRef } from "react";
import {
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar,
	IonGrid,
	IonRow,
	IonCol,
	IonText,
	IonBadge,
	IonSegment,
	IonSegmentButton,
	IonCard,
	IonCardHeader,
} from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";
import { scanReducer, initScan } from "../redux/scanSlice";
import { share, camera } from "ionicons/icons";
import "../components/ExploreContainer";
import "./qrborder.css";
var QRCode = require("qrcode.react");
const Scan: React.FC = () => {
	const dispatch = useDispatch();
	const userSlice = useSelector(scanReducer);
	const [color, setColor] = useState("green");
	return (
		<IonPage style={{ height: "100%" }}>
			<IonHeader>
				<IonToolbar>
					<IonTitle>Privascan</IonTitle>
				</IonToolbar>
				<IonSegment
					value={color}
					onIonChange={(e: any) => setColor(e.target.value)}>
					<IonSegmentButton value='green'>Verde</IonSegmentButton>
					<IonSegmentButton value='goldenrod'>Amarillo</IonSegmentButton>
					<IonSegmentButton value='red'>Rojo</IonSegmentButton>
				</IonSegment>
			</IonHeader>
			<IonContent fullscreen>
				{/* {newFunction()} */}
				<IonCard style={{ backgroundColor: color, height: "90%" }}>
					<div
						style={{
							width: "100%",
							height: "100%",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							backgroundColor: color,
							flexDirection: "column",
							color: "black",
							fontWeight: "bold",
							letterSpacing: 1,
							fontSize: 20,
						}}>
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								backgroundColor: "white",
								width: "70%",
								alignItems: "center",
								padding: 25,
							}}>
							{/* <div style={{ padding: 20, color: "green" }}>JUAN DOE</div> */}
							<div className='corners' style={{ color: color }}>
								<div className='top left'></div>
								<div className='top right'></div>
								<div className='bottom right'></div>
								<div className='bottom left'></div>
								<QRCode value='https://privascan.com' fgColor={color} />
							</div>

							{/* <div style={{ padding: 20, color: "green" }}>{color}</div> */}
						</div>
					</div>
				</IonCard>
			</IonContent>
		</IonPage>
	);
};

export default Scan;
function newFunction() {
	return (
		<div className='container'>
			<img src='assets/privascan_256_01.png' style={{ height: "30vh" }}></img>
		</div>
	);
}
