/** @format */

import React, { useEffect } from "react";
import {
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar,
	IonList,
	IonItem,
	IonLabel,
	IonIcon,
	IonBackButton,
	useIonViewWillEnter,
	IonButtons,
	IonText,
	IonNote,
	IonBadge,
} from "@ionic/react";
import ExploreContainer from "../components/ExploreContainer";
import { scan, checkmark, location } from "ionicons/icons";
import { initMap, mapReducer } from "../redux/mapSlice";
import { useDispatch, useSelector } from "react-redux";
import Moment from "react-moment";
import "moment-timezone";
import Scan from "./Scan";

const DataScans: React.FC = () => {
	const dispatch = useDispatch();
	const mapSlice = useSelector(mapReducer);
	useIonViewWillEnter(() => {
		dispatch(initMap({ init: true }));
	});
	useEffect(() => {
		console.log("MapSlice", mapSlice);
	}, [mapSlice.map.scans]);
	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot='start'>
						<IonBackButton defaultHref='/data'></IonBackButton>
					</IonButtons>

					<IonTitle>Data Scans</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent fullscreen>
				<IonHeader collapse='condense'>
					<IonToolbar>
						<IonTitle size='large'>Data Scans</IonTitle>
					</IonToolbar>
				</IonHeader>
				<IonList>
					{mapSlice.map.scans ? (
						mapSlice.map.scans.map((scan: any) => (
							<IonItem key={scan.id}>
								<IonIcon
									icon={location}
									color={
										scan.testresult === "positive" ? "danger" : "secondary"
									}></IonIcon>
								<IonLabel>
									<h3>
										<Moment format='MM/DD/YY hh:mmA'>{scan.createdAt}</Moment>
									</h3>

									<p>
										Lat: {scan.lat} | Lon: {scan.lon}
									</p>
								</IonLabel>
								<IonNote>
									<IonBadge>
										{scan.agemin}-{scan.agemax}Y
									</IonBadge>
								</IonNote>
							</IonItem>
						))
					) : (
						<h3>Loading</h3>
					)}
				</IonList>
			</IonContent>
		</IonPage>
	);
};

export default DataScans;
